import { combineReducers } from 'redux';
import { offline } from "@redux-offline/redux-offline";

import auth from './auth';
import layout from './layout';
import people from './people';
import transactions from './transactions';
import files from './files';

const store = combineReducers({
  auth,
  layout,
  people,
  transactions,
  files,
  offline
});

export default store;