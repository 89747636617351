import { Reducer } from "redux";
import {
  FileReducerType,
  FILE_ADD,
  FILE_ADD_ERROR,
  FILE_ADD_SUCCESS,
  FILE_ACTIVE_REPS,
  FILE_ADD_ACTIVE_FILE,
  FILE_ADD_FEED_FILE,
  FILE_ADD_GRID_FILE,
  FILE_CLEAR_ACTIVE_FILES,
  FILE_FEED_SUCCESS,
} from "./constants";
import merge from "lodash/merge";
import { normalizeArrayByKey } from "../../services/utils";
import { FileType } from "../../types";

const defaultState = {
  byId: {
    "033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df": {
      bid: 0,
      created_at: "2021-09-30T22:16:33.000000Z",
      id: "033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df",
      lg_hq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df%2F00f107bc287c2eea57e2ec678ed43a6ab3c0afdc6cc4a82b?alt=media&token=5b0e8d34-4718-4fda-bb29-317de2ec26aa",
      lg_lq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df%2F7b10bf684697e6d78b5d5a4476ae1056b47cf72279c363dd?alt=media&token=e26cb59e-e174-4792-be94-98fd66d1a105",
      md_hq_url: "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df%2Fc07dc9b615eeb15c4a27d19b6ec83a4eb8f17671a606641b?alt=media&token=0c59af0c-c440-482e-a606-c193a1e0eb2e",

      md_lq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df%2Fc07dc9b615eeb15c4a27d19b6ec83a4eb8f17671a606641b?alt=media&token=0c59af0c-c440-482e-a606-c193a1e0eb2e",
      person_id: "964c623c-8616-44be-8490-ff0c23ce6f16",
      reputation: "NEUTRAL",
      sq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df%2F43dd38aac03e63a29030ca99c6f949708ad25bdc33970274?alt=media&token=b5ac9e90-fb75-429a-bebc-50e741f58dbf",
      bl_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df%2F43dd38aac03e63a29030ca99c6f949708ad25bdc33970274?alt=media&token=b5ac9e90-fb75-429a-bebc-50e741f58dbf",
      status: "POSTED",
      updated_at: "2021-09-30T22:16:35.000000Z",
    },
    "652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6": {
      bid: 0,
      created_at: "2021-09-30T22:16:33.000000Z",
      id: "652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6",
      lg_hq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6%2F8450fdf3c79334c83c4a284fa939e40c78ab8610e9ef8a55?alt=media&token=b2208bc7-0c28-4771-829b-652ea76e1fac",
      lg_lq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6%2Fc727e60ed2ebdcbe3741d43d83a47afd423463875dca6293?alt=media&token=096e5944-f416-4c68-b05e-a7733cf5eebf",
      md_hq_url: "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6%2F860cf1ba1f2ed32e5632df286468dc8ce6a80ec81a773a03?alt=media&token=ae1bb236-3a4f-42d7-81b6-f7a6bb50e471",


      md_lq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6%2F860cf1ba1f2ed32e5632df286468dc8ce6a80ec81a773a03?alt=media&token=ae1bb236-3a4f-42d7-81b6-f7a6bb50e471",
      person_id: "964c623c-8616-44be-8490-ff0c23ce6f16",
      reputation: "NEUTRAL",
      sq_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6%2Fb55db0e88c83898ba61e72239b94e9c5b7b696066ca9de27?alt=media&token=2f06dd1e-8793-43a3-9726-e23079568072",
      bl_url:
      "https://firebasestorage.googleapis.com/v0/b/libertalia-6e404.appspot.com/o/remy%2F652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6%2Fb55db0e88c83898ba61e72239b94e9c5b7b696066ca9de27?alt=media&token=2f06dd1e-8793-43a3-9726-e23079568072",
      status: "POSTED",
      updated_at: "2021-09-30T22:16:35.000000Z",
    },
  },
  activeReps: [4],
  activeFiles: [],
  feedFiles: ["033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df", "652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6"],
  gridFiles: [],
};

const moneyReducer: Reducer<FileReducerType> = (
  state = defaultState,
  action: { type: String; payload?: any; meta?: any }
) => {
  switch (action.type) {
    case FILE_ADD: {
      return {
        ...state,
        byId: merge({}, state.byId, action.meta.normalizedFiles),
      };
    }
    case FILE_ADD_SUCCESS: {
      return {
        ...state,
        byId: merge({}, state.byId, action.meta.normalizedFiles),
      };
    }
    case FILE_ADD_ERROR: {
      return {
        ...state,
        byId: merge({}, state.byId, action.meta.normalizedFiles),
      };
    }
    case FILE_ACTIVE_REPS: {
      return {
        ...state,
        activeReps: action.payload.idxAry,
      };
    }
    case FILE_ADD_ACTIVE_FILE: {
      return {
        ...state,
        activeFiles: [...state.activeFiles, action.payload],
      };
    }
    case FILE_ADD_GRID_FILE: {
      return {
        ...state,
        gridFiles: [action.payload, ...state.gridFiles],
      };
    }
    case FILE_ADD_FEED_FILE: {
      let currentFiles = [...state.feedFiles];
      if(!currentFiles.includes(action.payload)){
        currentFiles = [action.payload, ...currentFiles];
      }
      return {
        ...state,
        feedFiles: currentFiles,
      };
    }
    case FILE_CLEAR_ACTIVE_FILES: {
      return {
        ...state,
        activeFiles: [],
      };
    }
    case FILE_FEED_SUCCESS: {
      const normalizedFiles = normalizeArrayByKey(action.payload, "id");
      // const fileArray = [...action.payload];
      // const stateArray = state.feedFiles;
      // let foo: Array<string> = [];
      let fileIds: Array<string> = [];
      action.payload.forEach((file: FileType) => {
        fileIds.push(file.id);
        // console.log(file.id);
        // // If it's not already there and it's not a duplicate
        // if(!stateArray.includes(file.id) && !foo.includes(file.id)){
        //   fileIds.push(file.id);
        // }
        // // Sets flag to not push identical posts
        // foo.push(file.id);
        // return true;
      });
      // If there's nothing in the feed, load the default
      if(fileIds.length <= 0){
        fileIds = ["033ea7de3659cc45fa8fcdf6114646b8c36bf9c4da20b7df", "652ee6d2f1f5e90f06aa8ae4fde829f8641b8bc28924c1c6"];
      }
      return {
        ...state,
        byId: merge({}, state.byId, normalizedFiles),
        feedFiles: fileIds,
      };
    }
    default:
      return state;
  }
};

export default moneyReducer;
