import { Router } from "./components/Router";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import store from "../src/store";
import logger from "redux-logger";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
// import { PING_ENDPOINT } from "./constants/endpoints";
// import axios from "axios";
// import _ from "lodash";

// const debouncedPing = _.debounce(async (callback: any) => {
//   try {
//     await axios({
//       url: PING_ENDPOINT,
//       method: "GET",
//       headers: {
//         accept: "Application/json",
//         "Content-Type": "Application/json",
//       },
//     });
//     return true;
//   } catch (error) {
//     return false;
//   }
// });

const newOfflineConfig = {
  ...offlineConfig,
  persistOptions: {
    blacklist: ['layout']
  },
  // detectNetwork: async (callback: any) => {
  //   setInterval(async () => {
  //     const result = await debouncedPing(callback);
  //     if (result) {
  //       callback({
  //         online: true,
  //       });
  //     } else {
  //       callback({
  //         online: false,
  //       });
  //     }
  //   }, 5000);
  // },
};

const DevApp = () => {
  const middleware: any = [thunk];

  const appStoreDev = createStore(
    store,
    {},
    compose(
      applyMiddleware(...middleware),
      offline(newOfflineConfig),
      applyMiddleware(logger)
    )
  );
  return (
    <>
      <Provider store={appStoreDev}>
        <Router />
      </Provider>
    </>
  )
}

const ProdApp = () => {
  const middleware: any = [thunk];

  const appStoreProd = createStore(
    store,
    {},
    compose(applyMiddleware(...middleware), offline(newOfflineConfig))
  );
  return (
    <>
      <Provider store={appStoreProd}>
        <Router />
      </Provider>
    </>
  )
}

function App() {
  return process.env.REACT_APP_BUILD === "PROD" ? <ProdApp /> : <DevApp />
}

export default App;
