import {
  ProfileSvg,
  ParrotSvg,
  CashIcon,
  PlusIcon,
  FacebookSvg,
  InstagramSvg,
} from "../common/Icons";
import { Button } from "../common/Button";
import { useSelector, useDispatch } from "react-redux";
import {
  setSlideout,
  setActiveSlideout,
  setModal,
  setActiveModal,
  setActiveLanding,
} from "../../store/layout/actions";
import store from "../../store";
import { XpMeter } from "../XpMeter";
import { getReputation } from "../../services/xpService";

export type RootState = ReturnType<typeof store>;

const TopNav = () => {
  const spinning = useSelector((state: RootState) => state.layout.isLoading);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const xpBalance = useSelector((state: RootState) => state.people.self.xp);

  const reputation = getReputation(xpBalance);

  const dispatch = useDispatch();

  const handleProfileButton = () => {
    if (isLoggedIn) {
      dispatch(setSlideout(true));
      dispatch(setActiveSlideout("SELF_PROFILE"));
    } else {
      dispatch(setModal(true));
      dispatch(setActiveModal("LOGIN"));
    }
  };

  const handleWalletButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveLanding("INVENTORY"));
    } else {
      dispatch(setModal(true));
      dispatch(setActiveModal("LOGIN"));
    }
  };

  const handlePostButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveModal("POST"));
    } else {
      dispatch(setActiveModal("LOGIN"));
    }
    dispatch(setModal(true));
  };

  const handleFeedButton = () => {
    dispatch(setActiveLanding("FEED"));
  };

  return (
    <>
      <div className="flex flex-row items-center">
        <>
          <div className="text-primary-700 pr-2">
            <Button
              onClick={() => handleFeedButton()}
              variant="round"
              Icon={ParrotSvg}
              spinning={spinning}
            />
          </div>
          <div>
            <div className="text-gray-700 font-bold text-xl">Libertalia</div>
            {isLoggedIn && (
              <div className="text-gray-700 text-xs">
                {reputation.reputation}: {xpBalance} / {reputation.levelCeiling}
              </div>
            )}
          </div>
        </>

        <div className="md:flex-grow flex-grow-0"></div>
        <div className="flex-grow md:flex-grow-0"></div>

        {isLoggedIn ? (
          <>
            <div className="text-primary-700 pr-2 ">
              <Button
                onClick={() => handlePostButton()}
                variant="round"
                Icon={PlusIcon}
                spinning={spinning}
              />
            </div>
            <div className="text-primary-700 pr-2 ">
              <Button
                onClick={() => handleWalletButton()}
                variant="round"
                Icon={CashIcon}
                spinning={spinning}
              />
            </div>
            <div className="text-primary-700">
              <Button
                onClick={() => handleProfileButton()}
                variant="round"
                Icon={ProfileSvg}
                spinning={spinning}
              />
            </div>
          </>
        ) : (
          <>
            <div className="text-gray-900 pr-2 ">
              <Button
                onClick={() => handlePostButton()}
                variant="gray-round"
                Icon={FacebookSvg}
                spinning={spinning}
              />
            </div>
            <div className="text-gray-900 pr-2">
              <Button
                onClick={() => handleWalletButton()}
                variant="gray-round"
                Icon={InstagramSvg}
                spinning={spinning}
              />
            </div>
          </>
        )}
      </div>
      <div className="">
        <XpMeter amount={xpBalance} />
      </div>
    </>
  );
};

export default TopNav;
