import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { ParrotIconSvg, BadgeCheckIcon, ClockIcon } from "../common/Icons";
import {
  setSlideout,
  setActiveSlideout,
  // setLoading,
} from "../../store/layout/actions";
import { loadPerson } from '../../store/people/actions';
// import { loadConfirmedTransactions } from '../../store/transactions/actions';

export type RootState = ReturnType<typeof store>;

const TxLog = () => {

  const activeTransactions = useSelector(
    (state: RootState) => state.transactions.activeTransactions
  );
  const stagedTransactions = useSelector(
    (state: RootState) => state.transactions.stagedTransactions
  );
  const confirmedTransactions = useSelector(
    (state: RootState) => state.transactions.confirmedTransactions
  );
  const targetUsername = useSelector((state: RootState) => state.people.activePerson.username);
  // const token = useSelector(
  //   (state: RootState) => state.auth.token
  // );

  const dispatch = useDispatch();

  const [mergedTransactions, setMergedTransactions] = useState(confirmedTransactions);

  useEffect(() => {
      setMergedTransactions([
        ...activeTransactions,
        ...stagedTransactions,
        ...confirmedTransactions,
      ]);
  }, [confirmedTransactions, activeTransactions, stagedTransactions]);

  // useEffect(() => {
  //   dispatch(setLoading(true));
  //   token && dispatch(loadConfirmedTransactions(token));
  // }, [token, dispatch]);

  return (
    <>
      <ul className="">
        {mergedTransactions?.map((transaction, idx) => {
          let transactionColor = "text-green-700";
          let accentColor = "bg-green-100";
          if (transaction.asset_qty < 0) {
            transactionColor = "text-red-700";
            accentColor = "bg-red-100";
          }
          const utcSeconds = transaction.provisioned_at;
          let transactionTime = new Date(0); // The 0 there is the key, which sets the date to the epoch
          transactionTime.setUTCSeconds(utcSeconds);
          return (
            <div key={idx}>
              {transaction.asset_name != null ? (
                <>
                  <li
                    key={transaction.id}
                    className={
                      "rounded-md border-1 mb-2 shadow-md cursor-pointer "
                    }
                    onClick={() => {
                      if (transaction.target_username) {
                        (transaction.target_username !== targetUsername) && dispatch(loadPerson(transaction.target_username));
                        dispatch(setActiveSlideout("TARGET_PROFILE"));
                        dispatch(setSlideout(true));
                      }
                    }}
                  >
                    <div
                      className={
                        "h-2 relative rounded-t-md overflow-hidden w-full " +
                        accentColor
                      }
                    ></div>
                    <div className="flex space-x-3 px-4 py-2">
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                          <div className="flex-shrink pr-2">
                            {transaction.target_name === "Libertalia" ? (
                              <ParrotIconSvg />
                            ) : (
                              <img
                                className="h-6 w-6 rounded-full"
                                src={transaction.target_photo_url}
                                alt=""
                              />
                            )}
                          </div>
                          {(transaction.status === "CHAIN_CONFIRMED" || transaction.status === "STRIPE_CONFIRMED") && (
                            <div className="text-yellow-500 pr-2">
                              <BadgeCheckIcon />
                            </div>
                          )}
                          {transaction.status === "PROVISIONED" && (
                            <div className="text-gray-300 pr-2">
                              <ClockIcon />
                            </div>
                          )}
                          <div className="flex-grow">
                            <h3 className="text-sm font-medium">
                              {transaction.target_name}
                            </h3>
                            <p className="text-xs text-gray-500">
                              {transactionTime.toLocaleString()}
                            </p>
                          </div>

                          <div className="">
                            <div
                              className={
                                "flex justify-end text-sm " + transactionColor
                              }
                            >
                              {transaction.asset_qty}
                            </div>
                            <div className="flex justify-end text-xs text-gray-400">
                              {transaction.asset_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </ul>
    </>
  );
};

export default TxLog;
