import { Reducer } from "redux";
import merge from "lodash/merge";
import { normalizeArrayByKey } from "../../services/utils";
import {
  PEOPLE_LOAD,
  PEOPLE_LOAD_SUCCESS,
  PEOPLE_LOAD_ERROR,
  PEOPLE_SELF_LOAD,
  PEOPLE_SELF_LOAD_ERROR,
  PEOPLE_SELF_LOAD_SUCCESS,
  PEOPLE_UPDATE_SUCCESS,
  PEOPLE_UPDATE,
  PEOPLE_UPDATE_ERROR,
  PEOPLE_SELF_ACTIVATE,
  PEOPLE_SELF_UPDATE_BALANCE,
  PEOPLE_SELF_XP_UPDATE_BALANCE,
  PEOPLE_UPDATE_ASSET_BALANCE,
  PeopleReducerTypes,
  PEOPLE_UPDATE_VALIDATION_ERROR,
  PEOPLE_UPDATE_INVALID_FIELDS,
  PEOPLE_UPDATE_XP_ASSET_BALANCE,
} from "./constants";

const defaultState = {
  byId: {
    "964c623c-8616-44be-8490-ff0c23ce6f16": {
      asset_id: "pdc1139587eff614c",
      asset_name: "remybux",
      blockchain_address: "1Ecfq53aRHkjFdnThuFaHWWBuc7JRCvZzLrEw3",
      created_at: "2021-09-30T22:11:33.000000Z",
      email: "remy.bork@gmail.com",
      id: "1d7313ba-0ea4-4695-b460-cfd02f11a842",
      name: "Remy Bork",
      photo_url:
        "https://lh3.googleusercontent.com/a-/AOh14GjQMyjF3vKPOnjpAUUzWlkGIbDMk7Qwi7f3ieOS=s400-c",
      updated_at: "2021-10-01T16:18:09.000000Z",
      user_id: "964c623c-8616-44be-8490-ff0c23ce6f16",
      username: "remybork",
      xp_asset_id: "peb1081e3f4ab06d5",
      xp_asset_balance: 0,
      asset_balance: 0,
    },
  },
  recent: [],
  isPeopleLoading: false,
  activePerson: {
    asset_id: "pdc1139587eff614c",
    asset_name: "remybux",
    blockchain_address: "1Ecfq53aRHkjFdnThuFaHWWBuc7JRCvZzLrEw3",
    created_at: "2021-09-30T22:11:33.000000Z",
    email: "remy.bork@gmail.com",
    id: "1d7313ba-0ea4-4695-b460-cfd02f11a842",
    name: "Remy Bork",
    photo_url:
      "https://lh3.googleusercontent.com/a-/AOh14GjQMyjF3vKPOnjpAUUzWlkGIbDMk7Qwi7f3ieOS=s400-c",
    updated_at: "2021-10-01T16:18:09.000000Z",
    user_id: "964c623c-8616-44be-8490-ff0c23ce6f16",
    username: "remybork",
    xp_asset_id: "peb1081e3f4ab06d5",
    xp_asset_balance: 0,
    asset_balance: 0,
  },
  self: {
    id: "0",
    name: "",
    username: "",
    balances: [{ name: "", id: "DBLN", qty: 0 }],
    blockchain_address: "",
    doubloonBalance: 0,
    xp: 0,
    asset_name: "",
    initials: "",
    email: "",
    title: "",
    photo_url: "",
    stripe_account_link: "",
    payouts_enabled: false,
    facebook_link: "",
    instagram_link: "",
    soundcloud_link: "",
    tiktok_link: "",
    validationError: "",
    invalidFields: [""],
  },
};

const authReducer: Reducer<PeopleReducerTypes> = (
  state = defaultState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case PEOPLE_SELF_LOAD: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_SELF_LOAD_SUCCESS: {
      let normalizedPayload = normalizeArrayByKey(
        [action.payload.success.user.profile],
        "id"
      );
      return {
        ...state,
        byId: merge({}, state.byId, normalizedPayload),
        isPeopleLoading: false,
        self: {
          ...state.self,
          id: action.payload.success.user.profile.id,
          name: action.payload.success.user.profile.name,
          username: action.payload.success.user.profile.username,
          asset_name: action.payload.success.user.profile.asset_name,
          balances: action.payload.success.user.profile.balances,
          blockchain_address:
            action.payload.success.user.profile.blockchain_address,
          doubloonBalance: action.payload.success.user.profile.doubloonBalance,
          xp: action.payload.success.user.profile.xp,
          initials: action.payload.success.user.profile.initials,
          email: action.payload.success.user.profile.email,
          title: action.payload.success.user.profile.title,
          photo_url: action.payload.success.user.profile.photo_url,
          stripe_account_link:
            action.payload.success.user.profile.stripe_account_link,
          payouts_enabled: action.payload.success.user.profile.payouts_enabled,
          facebook_link: action.payload.success.user.profile.facebook_link,
          instagram_link: action.payload.success.user.profile.instagram_link,
          soundcloud_link: action.payload.success.user.profile.soundcloud_link,
          tiktok_link: action.payload.success.user.profile.tiktok_link,
          validationError: action.payload.success.user.profile.validationError,
          invalidFields: ["NONE"],
          subscriptons: action.payload.success.user.subscriptions,
          images: action.payload.success.user.images,
        },
      };
    }
    case PEOPLE_SELF_LOAD_ERROR: {
      return {
        ...state,
        isPeopleLoading: false,
        self: {
          id: "0",
          name: "",
          username: "",
          balances: [{ name: "", id: "DBLN", qty: 0 }],
          blockchain_address: "",
          doubloonBalance: 0,
          initials: "",
          email: "",
          title: "",
          photo_url: "",
          stripe_account_link: null,
          payouts_enabled: false,
          facebook_link: "",
          instagram_link: "",
          soundcloud_link: "",
          tiktok_link: "",
          validationErrror: "",
          invalidFields: ["NONE"],
        },
      };
    }
    case PEOPLE_LOAD: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_LOAD_SUCCESS: {
      let payload = action.payload;
      payload.xp_asset_balance = 0;
      payload.asset_balance = 0;
      let recentArray = [...state.recent];
      if (!state.recent.includes(payload.id)) {
        recentArray = [payload.id, ...state.recent];
        if (recentArray.length > 5) {
          recentArray.pop();
        }
      }

      let normalizedPayload = normalizeArrayByKey([payload], "id");
      return {
        ...state,
        isPeopleLoading: false,
        activePerson: payload,
        recent: recentArray,
        byId: merge({}, state.byId, normalizedPayload),
      };
    }
    case PEOPLE_LOAD_ERROR: {
      return {
        ...state,
        isPeopleLoading: false,
        activePerson: {
          id: "0",
          name: "",
          username: "",
          photo_url: "",
          asset_id: "",
          asset_name: "",
          facebook_link: "",
          instagram_link: "",
          soundcloud_link: "",
          tiktok_link: "",
        },
      };
    }
    case PEOPLE_UPDATE: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_UPDATE_SUCCESS: {
      return {
        ...state,
        isPeopleLoading: false,
        id: action.payload.id,
        name: action.payload.name,
        username: action.payload.username,
        asset_name: action.payload.asset_name,
        balances: action.payload.balances,
        blockchain_address: action.payload.blockchain_address,
        doubloonBalance: action.payload.doubloonBalance,
        xp: action.payload.xp,
        initials: action.payload.initials,
        email: action.payload.email,
        title: action.payload.title,
        photo_url: action.payload.photo_url,
        stripe_account_link: action.payload.stripe_account_link,
        payouts_enabled: action.payload.payouts_enabled,
        facebook_link: action.payload.facebook_link,
        instagram_link: action.payload.instagram_link,
        soundcloud_link: action.payload.soundcloud_link,
        tiktok_link: action.payload.tiktok_link,
        validationError: action.payload.validationError,
        invalidFields: ["NONE"],
      };
    }
    case PEOPLE_UPDATE_ERROR: {
      return {
        ...state,
        isPeopleLoading: false,
      };
    }
    case PEOPLE_SELF_ACTIVATE: {
      return {
        ...state,
        self: action.payload,
      };
    }
    case PEOPLE_SELF_UPDATE_BALANCE: {
      return {
        ...state,
        self: {
          ...state.self,
          doubloonBalance: action.payload,
        },
      };
    }
    case PEOPLE_SELF_XP_UPDATE_BALANCE: {
      return {
        ...state,
        self: {
          ...state.self,
          xp: action.payload,
        },
      };
    }
    case PEOPLE_UPDATE_ASSET_BALANCE: {
      return {
        ...state,
        activePerson: {
          ...state.activePerson,
          asset_balance: action.payload,
        },
      };
    }
    case PEOPLE_UPDATE_XP_ASSET_BALANCE: {
      return {
        ...state,
        activePerson: {
          ...state.activePerson,
          xp_asset_balance: action.payload,
        },
      };
    }
    case PEOPLE_UPDATE_VALIDATION_ERROR: {
      return {
        ...state,
        self: {
          ...state.self,
          validationError: action.payload,
        },
      };
    }
    case PEOPLE_UPDATE_INVALID_FIELDS: {
      return {
        ...state,
        self: {
          ...state.self,
          invalidFields: [...action.payload],
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
