import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../store";
import { getInitials } from "../../services/utils";
import RecentPerson from "./RecentPerson";
import { getReputation } from "../../services/xpService";
import { PersonType } from "../../types";

export type RootState = ReturnType<typeof store>;

const RecentPeople = () => {

  const allPeople = useSelector((state: RootState) => state.people.byId);

  const recent = useSelector((state: RootState) => state.people.recent);

  const [recentPeople, setRecentPeople] = useState<Array<PersonType>>([]);

  useEffect(() => {
    let recentPeople: any = [];
    recent.map((personId) => {
      recentPeople.push(allPeople[personId]);
      return true;
    });
    setRecentPeople(recentPeople);
  }, [recent, allPeople]);

  return (
    <div className="flex grid grid-cols-5 mx-auto">
      {recentPeople.map((person, idx) => {
        const personRep = getReputation(person.xp_asset_balance);
        const bgColor = "bg-" + personRep.color + "-700";
        const ringColor = "ring-" + personRep.color + "-400";
        return (
          <div key={idx} className="flex mx-auto py-4">
            {person && (
              <RecentPerson
                initials={getInitials(person.name).toString()}
                photo_url={person.photo_url}
                target_id={person.id}
                ringColor={ringColor}
                bgColor={bgColor}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RecentPeople;
