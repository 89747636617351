import React, { useState } from "react";
import {
  setSlideout,
  setToast,
  setActiveToast,
} from "../../store/layout/actions";
import {
  personUpdate,
  selfActivate,
  setValidationError,
  setInvalidFields,
} from "../../store/people/actions";
import { Button } from "../../components/common/Button";
import { SelfProfileHeader } from "./index";
import {
  SpinnerSvg,
  FacebookSvg,
  InstagramSvg,
  TiktokSvg,
  SoundcloudSvg,
  ParrotIconSvg,
  ProfileIconSvg,
  CurrencyDollarSvg,
} from "../../components/common/Icons";
import { SettingsInput } from "./index";
import { validateUsername, validateAssetName } from "../../services/authService";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
export type RootState = ReturnType<typeof store>;

const Settings = () => {
  const self = useSelector((state: RootState) => state.people.self);
  const uid = useSelector((state: RootState) => state.auth.id);
  const token = useSelector((state: RootState) => state.auth.token);
  const errorArray = useSelector(
    (state: RootState) => state.people.self.invalidFields
  );
  const originalUsername = self.username;
  const originalAssetName = self.asset_name;

  const dispatch = useDispatch();

  // This is causing validation errors
  // const delayedUsername = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
  //   _.debounce((q) => checkUsername(q), 100), 
  //   [] 
  // ); 

  // const delayedAssetName = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
  //   _.debounce((q) => checkAssetName(q), 100), 
  //   [] 
  // ); 

  const [isSettingsWaiting, setIsSettingsWaiting] = useState(false);

  const isValid = async (
    transactionSchema: any,
    transactionObject: any,
    currentField: string,
    currentValue: string
  ) => {
    await transactionSchema.isValid(transactionObject).then((res: boolean) => {
      res &&
        dispatch(
          selfActivate({
            ...self,
            id: uid,
            name: currentField === "name" ? currentValue : self.name,
            username:
              currentField === "username" ? currentValue : self.username,
            asset_name:
              currentField === "asset_name" ? currentValue : self.asset_name,
            facebook_link:
              currentField === "facebook_link"
                ? currentValue
                : self.facebook_link,
            instagram_link:
              currentField === "instagram_link"
                ? currentValue
                : self.instagram_link,
            tiktok_link:
              currentField === "tiktok_link" ? currentValue : self.tiktok_link,
            soundcloud_link:
              currentField === "soundcloud_link"
                ? currentValue
                : self.soundcloud_link,
          })
        );
    });
    return true;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentField = event.currentTarget.id;
    const currentValue = event.currentTarget.value;

    const transactionObject = {
      amount: currentValue,
    };

    if (currentField === "username") {
      const lettersOnly: any = (value: string) =>
        /^[A-Za-z]+$/.test(value) || value.length === 0;
      const transactionSchema = yup.object().shape({
        amount: yup
          .string()
          .test(
            "inputEntry",
            "The field should have letters only",
            lettersOnly
          ),
      });
      isValid(
        transactionSchema,
        transactionObject,
        currentField,
        currentValue
      ).then(() => {
        removeError('USERNAME');
        checkUsername(currentValue);
      });
    }

    if (currentField === "name") {
      const nameConstraints: any = (value: string) =>
      /^[A-Za-z ]+$/.test(value) || value.length === 0;
      const transactionSchema = yup.object().shape({
        amount: yup
          .string()
          .test(
            "inputEntry",
            "The field should have letters only",
            nameConstraints
          ),
      });
      isValid(transactionSchema, transactionObject, currentField, currentValue).then(() => {
        checkDisplayName(currentValue);
      });
    }

    if (currentField === "facebook_link") {
      const transactionSchema = yup.object().shape({ amount: yup.string() });
      isValid(
        transactionSchema,
        transactionObject,
        currentField,
        currentValue
      ).then(() => {
        checkSocialUrl(currentValue, "FACEBOOK");
      });
    }

    if (currentField === "instagram_link") {
      const transactionSchema = yup.object().shape({ amount: yup.string() });
      isValid(
        transactionSchema,
        transactionObject,
        currentField,
        currentValue
      ).then(() => {
        checkSocialUrl(currentValue, "INSTAGRAM");
      });
    }

    if (currentField === "soundcloud_link") {
      const transactionSchema = yup.object().shape({ amount: yup.string() });
      isValid(
        transactionSchema,
        transactionObject,
        currentField,
        currentValue
      ).then(() => {
        checkSocialUrl(currentValue, "SOUNDCLOUD");
      });
    }

    if (currentField === "tiktok_link") {
      const transactionSchema = yup.object().shape({ amount: yup.string() });
      isValid(
        transactionSchema,
        transactionObject,
        currentField,
        currentValue
      ).then(() => {
        checkSocialUrl(currentValue, "TIKTOK");
      });
    }

    if (currentField === "asset_name") {
      const lettersOnly: any = (value: string) =>
        /^[A-Za-z]+$/.test(value) || value.length === 0;
      const transactionSchema = yup.object().shape({
        amount: yup
          .string()
          .test(
            "inputEntry",
            "The field should have letters only",
            lettersOnly
          ),
      });
      isValid(
        transactionSchema,
        transactionObject,
        currentField,
        currentValue
      ).then(() => {
        removeError('ASSET_NAME');
        checkAssetName(currentValue);
      });
    }
  };

  function arrayRemove(arr: Array<string>, value: string) { 
    return arr.filter(function(ele){ 
        return ele !== value; 
    });
  }

  const addError = (error: string) => {
    let newArray = errorArray;
    !errorArray?.includes(error) && newArray?.push(error);
    newArray && dispatch(setInvalidFields(newArray));

  };

  const removeError = (error: string) => {
    if (errorArray?.includes(error)){
      let newArray = arrayRemove(errorArray, error);
      newArray ? dispatch(setInvalidFields([...newArray])) : dispatch(setInvalidFields(['NONE']))
    }
  };



  const checkSocialUrl = async (url: string, platform: string) => {
    // const urlReg =
    //   /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    const facebookReg =
      /^(?:https?:\/\/)?(?:www\.|m\.|mobile\.|touch\.|mbasic\.)?(?:facebook\.com|fb(?:\.me|\.com))\/(?!$)(?:(?:\w)*#!\/)?(?:pages\/)?(?:photo\.php\?fbid=)?(?:[\w-]*\/)*?(?:\/)?(?:profile\.php\?id=)?([^/?&\s]*)(?:\/|&|\?)?.*$/;
    const instagramReg = /(?:instagram.com\/)[A-Za-z0-9_.]+/;
    const soundcloudReg = /(?:soundcloud.com|snd.sc)\/(.*)$/;
    const tiktokReg = /(?:tiktok.com)\/(.*)$/;

    let invalidSocialMessage = "";
    let selectedUrlPrefix = "";
    let selectedReg = /''/;

    if (platform === "FACEBOOK") {
      selectedReg = facebookReg;
      invalidSocialMessage = "Invalid Facebook URL";
      selectedUrlPrefix = "https://facebook.com/";
    }
    if (platform === "INSTAGRAM") {
      selectedReg = instagramReg;
      invalidSocialMessage = "Invalid Instagram URL";
      selectedUrlPrefix = "https://instagram.com/";
    }
    if (platform === "SOUNDCLOUD") {
      selectedReg = soundcloudReg;
      invalidSocialMessage = "Invalid Soundcloud URL";
      selectedUrlPrefix = "https://soundcloud.com/";
    }
    if (platform === "TIKTOK") {
      selectedReg = tiktokReg;
      invalidSocialMessage = "Invalid Tiktok URL";
      selectedUrlPrefix = "https://tiktok.com/";
    }

    if (url.match(selectedReg)) {
      removeError(platform);
    } else {
      if (url.length === 0) {
        removeError(platform);
        dispatch(setValidationError(""));
      } else {
        const splitUrl = url.split(".com/");
        const sanitizedURL = selectedUrlPrefix + splitUrl[1];
        console.log(sanitizedURL);
        addError(platform);
        dispatch(setValidationError(invalidSocialMessage));
      }
    }

  };

  const checkDisplayName = (displayname: string) => {
    if (displayname.length > 4 && displayname.length < 43) {
      removeError('NAME');
    } else {
      addError('NAME');
      dispatch(setValidationError("Display Name must be between 5 and 42 characters"));
    }
    if (displayname.length === 0) {
      removeError('NAME');
      dispatch(setValidationError(""));
    }
  }

  const checkAssetName = (assetname: string) => {
    setIsSettingsWaiting(true);
    if (assetname === originalAssetName){
      removeError('ASSET_NAME');
      setIsSettingsWaiting(false);
    }
    if (assetname.length > 4 && assetname.length < 31) {
      validateAssetName(assetname).then((res) => {
        console.log(res)
        if (res.length === 0) {
          removeError('ASSET_NAME');
          setIsSettingsWaiting(false);
        } else {
          if (res[0].asset_name !== self.asset_name) {
            addError('ASSET_NAME');
            dispatch(setValidationError("Currency already exists"));
            setIsSettingsWaiting(false);
          } else {
            removeError('ASSET_NAME');
          }
        }
      });
    } else {
      addError('ASSET_NAME');
      dispatch(setValidationError("Currency must be between 5 and 30 characters"));
      setIsSettingsWaiting(false);
    }
    if (assetname.length === 0) {
      removeError('ASSET_NAME');
      setIsSettingsWaiting(false);
    }
  };

  const checkUsername = (username: string) => {
    setIsSettingsWaiting(true);
    if (username.length > 4 && username.length < 43) {
      validateUsername(username).then((res) => {
        if (res.length === 0) {
          removeError('USERNAME');
          setIsSettingsWaiting(false);
        } else {
          if (res[0].id !== uid) {
            addError('USERNAME');
            dispatch(setValidationError("Username already exists"));
            setIsSettingsWaiting(false);
          }
        }
      });
    } else {
      addError('USERNAME');
      dispatch(setValidationError("Username must be between 5 and 42 characters"));
      setIsSettingsWaiting(false);
    }
    if (username.length === 0) {
      addError('USERNAME');
      dispatch(setValidationError("Username is required"));
      setIsSettingsWaiting(false);
    }
    if (username === originalUsername){
      removeError('USERNAME');
      setIsSettingsWaiting(false);
    }
  };

  const handleClick = () => {
    self?.username &&
      validateUsername(self.username).then((res) => {
        if (res.length === 0 || res[0].id === uid) {
          token && dispatch(personUpdate(token, self));
          dispatch(setSlideout(false));
          dispatch(setActiveToast("SETTINGS"));
          dispatch(setToast());
        } else {
          dispatch(setActiveToast("USER_EXISTS"));
          dispatch(setToast());
        }
      });
  };

  return (
    <>
      <div className="">
        <SelfProfileHeader image_url={self.photo_url} name={self.name} />
      </div>

      <div className="bg-white px-4 pt-2">
        <div className="h-auto">
          {/* Messages */}
          <div className="flex md:px-6 px-4 justify-end pointer-events-none text-primary-700 text-xs">
            {!isSettingsWaiting &&
              errorArray &&
              errorArray.length > 1 &&
              self.validationError}
            {isSettingsWaiting && (
              <>
                <SpinnerSvg size="h-4 w-4 mr-3" /> Validating{" "}
              </>
            )}
            {!isSettingsWaiting && errorArray && errorArray.length === 1 &&
            <div className="h-4"></div>
            }
          </div>

          <div className="md:px-6 px-2 pb-2">
            {/* Libertalia Settings */}
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Settings
              </label>
              <SettingsInput
                Icon={<ParrotIconSvg />}
                label="Username"
                fieldName="username"
                error={errorArray?.includes("USERNAME") ? true : false}
                onChange={handleChange}
                value={self.username}
              />
              <SettingsInput
                Icon={<ProfileIconSvg />}
                label="Display Name"
                fieldName="name"
                error={errorArray?.includes("NAME") ? true : false}
                onChange={handleChange}
                value={self.name}
              />
              <SettingsInput
                Icon={<CurrencyDollarSvg />}
                label="Currency Name"
                fieldName="asset_name"
                error={errorArray?.includes("ASSET_NAME") ? true : false}
                onChange={handleChange}
                value={self.asset_name}
              />
            </div>

            {/* Social Media Links */}
            <div className="sm:col-span-4 border-t py-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Social Media Links
              </label>
              <SettingsInput
                Icon={<FacebookSvg />}
                label="Facebook"
                fieldName="facebook_link"
                error={errorArray?.includes("FACEBOOK") ? true : false}
                onChange={handleChange}
                value={self.facebook_link}
              />
              <SettingsInput
                Icon={<InstagramSvg />}
                label="Instagram"
                fieldName="instagram_link"
                error={errorArray?.includes("INSTAGRAM") ? true : false}
                onChange={handleChange}
                value={self.instagram_link}
              />
              <SettingsInput
                Icon={<SoundcloudSvg />}
                label="Soundcloud"
                fieldName="soundcloud_link"
                error={errorArray?.includes("SOUNDCLOUD") ? true : false}
                onChange={handleChange}
                value={self.soundcloud_link}
              />
              <SettingsInput
                Icon={<TiktokSvg />}
                label="TikTok"
                fieldName="tiktok_link"
                error={errorArray?.includes("TIKTOK") ? true : false}
                onChange={handleChange}
                value={self.tiktok_link}
              />
            </div>

            <div className="flex justify-end py-2">
              <Button
                label="Save"
                variant={errorArray?.length === 1 ? "primary" : "disabled"}
                onClick={() => handleClick()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
