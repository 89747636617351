import {useSelector, useDispatch} from 'react-redux';
import { setActiveLanding } from '../../../store/layout/actions';
import store from "../../../store";
export type RootState = ReturnType<typeof store>;

const Tabs = () => {
  const { activeLanding } = useSelector((state: RootState) => state.layout);

  const dispatch = useDispatch();

  const activeClasses =
    "border-primary text-primary-700 whitespace-nowrap font-medium text-sm";
  const inactiveClasses =
    "border-transparent text-primary-200 hover:text-primary-700 hover:border-primary-700 whitespace-nowrap font-medium text-sm";

  return (
    <div>
      <div className="block pb-4 px-4">
        <div className="">
          <nav className="-mb-px flex space-x-8" aria-label="StoreTabs">
          <button
              key="INVENTORY"
              className={
                activeLanding === "INVENTORY" ? activeClasses : inactiveClasses
              }
              aria-current={activeLanding === "INVENTORY" ? "page" : undefined}
              onClick={() => {
                dispatch(setActiveLanding("INVENTORY"));
              }
              }
            >
              Inventory
            </button>
            <button
              key="TXLOG"
              className={
                activeLanding === "TXLOG" ? activeClasses : inactiveClasses
              }
              aria-current={activeLanding === "TXLOG" ? "page" : undefined}
              onClick={() => {
                dispatch(setActiveLanding("TXLOG"));
              }
              }
            >
              Transaction Log
            </button>
            <button
              key="PORTFOLIO"
              className={
                activeLanding === "PORTFOLIO" ? activeClasses : inactiveClasses
              }
              aria-current={activeLanding === "PORTFOLIO" ? "page" : undefined}
              onClick={() => {
                dispatch(setActiveLanding("PORTFOLIO"));
              }}
            >
              Portfolio
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
