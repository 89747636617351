import { Image } from '../common/Image';

type AvatarPropsType = {
  image_url: string;
};

const Avatar = (props: AvatarPropsType) => {
  const { image_url } = props;

  return (
    <div className="mx-auto">
  
        <div className="">
          <div className="flex justify-center mx-auto rounded-full ring ring-white md:h-24 md:w-24 h-20 w-20 rounded-full mx-auto">
            <Image 
              url={image_url}
              className="ring ring-white md:h-24 md:w-24 h-20 w-20 rounded-full mx-auto"
              onLoad={() => {return null}}
            />
          </div>

        </div>
    </div>
  );
};

export default Avatar;
