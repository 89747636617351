import { useState, useEffect } from "react";
import { Button } from "../../components/common/Button";
import {
  FacebookSvg,
  InstagramSvg,
  TiktokSvg,
  SoundcloudSvg,
  ArrowLeftIcon,
} from "../../components/common/Icons";
import { useSelector, useDispatch } from "react-redux";
import {
  setAssetBalance,
  setSelfDoubloonBalance,
  setXpAssetBalance,
} from "../../store/people/actions";
import {
  setSlideout,
  setModal,
  setActiveModal,
  setLoading,
  setToast,
  setActiveToast,
} from "../../store/layout/actions";
import {
  addConfirmedTransaction,
  exchangeActivate,
  tip,
  loadConfirmedTransactions,
} from "../../store/transactions/actions";
import store from "../../store";
import { TipTransactionType } from "../../types";
import { Image } from "../../components/common/Image";
import { XpMeter } from "../../components/XpMeter";
import { getReputation } from "../../services/xpService";
import { AssetGrid } from '../../components/AssetGrid';

export type RootState = ReturnType<typeof store>;

const Profile = () => {
  const activePerson = useSelector(
    (state: RootState) => state.people.activePerson
  );
  const balance = useSelector(
    (state: RootState) => state.people.self.doubloonBalance
  );
  const userCurrencyBalance = useSelector(
    (state: RootState) => state.people.activePerson.asset_balance
  );
  const xpBalance = useSelector(
    (state: RootState) => state.people.activePerson.xp_asset_balance
  );
  const token = useSelector((state: RootState) => state.auth.token);
  const selfDoubloonBalance = useSelector(
    (state: RootState) => state.people.self.doubloonBalance
  );
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const gridFileDetails = useSelector(
    (state: RootState) => state.people.activePerson.files
  );

  const dispatch = useDispatch();

  const [tipAmount, setTipAmount] = useState(100);
  const [activeSocialLink, setActiveSocialLink] = useState("NONE");
  const [isCustomTipOpen, setIsCustomTipOpen] = useState(false);

  const reputation = getReputation(xpBalance);

  const handleOpenStore = () => {
    if (isLoggedIn) {
      dispatch(setActiveModal("BUY_DOUBLOONS"));
      dispatch(setModal(true));
    } else {
      dispatch(setSlideout(false));
      dispatch(setActiveModal("LOGIN"));
      dispatch(setModal(true));
    }
  };

  const handleTip = (amount: number, target_id: string) => {
    if (isLoggedIn) {
      const tipObject: TipTransactionType = {
        id: "p" + require("crypto").randomBytes(8).toString("hex"),
        target_id: target_id,
        asset_qty: amount,
        asset_name: "Doubloons",
        asset_id: "DBLN",
        target_name: activePerson.name,
        target_photo_url: activePerson.photo_url,
        target_username: activePerson.username,
        provisioned_at: Date.now() / 1000,
        status: "PROVISIONED",
      };
      let userAssetBalance = 0;
      userCurrencyBalance && (userAssetBalance = userCurrencyBalance);
      dispatch(setXpAssetBalance(xpBalance + amount));
      dispatch(setSelfDoubloonBalance(selfDoubloonBalance - amount));
      token && dispatch(tip(tipObject, token));
      dispatch(addConfirmedTransaction(tipObject));
      dispatch(exchangeActivate((balance - amount).toString()));
      dispatch(setAssetBalance(userAssetBalance + amount));
      token && dispatch(loadConfirmedTransactions(token));
      dispatch(setLoading(false));
      dispatch(setActiveToast("TIP"));
      dispatch(setToast());
    } else {
      dispatch(setSlideout(false));
      dispatch(setActiveModal("LOGIN"));
      dispatch(setModal(true));
    }
  };

  useEffect(() => {
    activeSocialLink !== "NONE" &&
      window.location.assign("https://" + activeSocialLink);
  }, [activeSocialLink]);

  const coverUrl = gridFileDetails && gridFileDetails[0]?.md_hq_url 

  return (
    <>
      <div
        className="bg-cover flex"
        style={{ backgroundImage: `url(${coverUrl})` }}
      >
        <div className="bg-opacity-50 pt-6
        flex justify-center grid items-center bg-gray-900 px-6 grid grid-cols-3">
          <div className="flex pl-2 pr-4 pb-6 justify-center">
            <Image
              url={activePerson.photo_url}
              className="ring ring-white h-auto rounded-full"
              onLoad={() => {
                return true;
              }}
            />
          </div>

          <div className="col-span-2 pb-3 pr-1 pl-1">
            <div className="flex justify-left text-white text-xl font-bold">
              {activePerson.name}
            </div>
            <div>
              <div className="grid grid-cols-4 mb-2 mt-1 text-white w-32">
                {activePerson.facebook_link ? (
                  <div className="">
                    <Button
                      variant="flat-2"
                      Icon={FacebookSvg}
                      onClick={() =>
                        setActiveSocialLink(
                          activePerson.facebook_link || "NONE"
                        )
                      }
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                {activePerson.instagram_link ? (
                  <div className="">
                    <Button
                      variant="flat-2"
                      Icon={InstagramSvg}
                      onClick={() =>
                        setActiveSocialLink(
                          activePerson.instagram_link || "NONE"
                        )
                      }
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                {activePerson.tiktok_link ? (
                  <div className="">
                    <Button
                      variant="flat-2"
                      Icon={TiktokSvg}
                      onClick={() =>
                        setActiveSocialLink(activePerson.tiktok_link || "NONE")
                      }
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                {activePerson.soundcloud_link ? (
                  <div className="">
                    <Button
                      variant="flat-2"
                      Icon={SoundcloudSvg}
                      onClick={() =>
                        setActiveSocialLink(
                          activePerson.soundcloud_link || "NONE"
                        )
                      }
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="grid grid-cols-2 mt-2">
                <div className="flex-shrink text-white text-xs">
                  <div>{reputation.reputation}</div>
                  <div>
                    {xpBalance} / {reputation.levelCeiling}
                  </div>
                </div>
                <div className="flex-grow text-white text-xs text-right">
                  <div>{activePerson?.asset_name}</div>
                  <div>{userCurrencyBalance}</div>
                </div>
              </div>
              <XpMeter amount={xpBalance} />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pb-6">
        <>
          <div className="sm:px-10 pt-5 px-8">
            <div className="flex ">
              {isCustomTipOpen && (
                <div>
                  <button
                    onClick={() => setIsCustomTipOpen(false)}
                    className="text-gray-700"
                  >
                    <ArrowLeftIcon />
                  </button>
                  <div className="w-full gap-4 grid grid-cols-3 justify-center flex">
                    <div className="min-w-0 m-1 py-2 col-span-2">
                      <input
                        type="number"
                        name="tipAmount"
                        id="tipAmount"
                        className="focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        onChange={(evt: any) =>
                          setTipAmount(parseInt(evt.target.value))
                        }
                        value={tipAmount}
                      />
                    </div>
                    <div className="min-w-0 m-1 py-2">
                      {tipAmount <= balance ? (
                        <Button
                          onClick={() => handleTip(tipAmount, activePerson.id)}
                          variant={"primary"}
                          fullWidth
                          label={"Tip"}
                        />
                      ) : (
                        <Button
                          onClick={() => handleOpenStore()}
                          variant={"primary"}
                          fullWidth
                          label={"Tip"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!isCustomTipOpen && (
              <>
                <div className="flex justify-center items-center py-2 font-bold text-lg px-2 md:px-4 text-gray-500 grid grid-cols-2">
                  <p>Send Doubloons</p>
                  <button
                    onClick={() => setIsCustomTipOpen(true)}
                    className="text-sm font-bold text-primary-400 flex justify-end hover:text-primary-900"
                  >
                    Custom Amount
                  </button>
                </div>
                <div className="flex gap-4 space-around mx-auto justify-center">
                  <div className="h-full shadow-md">
                    {balance > 500 ? (
                      <button
                        onClick={() => handleTip(500, activePerson.id)}
                        className="bg-primary-700 hover:bg-primary-900 rounded p-4 items-center text-white"
                      >
                        <p className="text-2xl font-bold">500</p>
                        {/* <img src={CoinsSm} loading="lazy" alt="" /> */}
                        <p className="text-sm">Doubloons</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleOpenStore()}
                        className="bg-primary-700 hover:bg-primary-900 rounded p-4 items-center text-white"
                      >
                        <p className="text-2xl font-bold">500</p>
                        {/* <img src={CoinsSm} loading="lazy" alt="" /> */}
                        <p className="text-sm">Doubloons</p>
                      </button>
                    )}
                  </div>

                  <div className="h-full shadow-md">
                    {balance > 1000 ? (
                      <button
                        onClick={() => handleTip(1000, activePerson.id)}
                        className="bg-primary-700 hover:bg-primary-900 rounded p-4 items-center text-white"
                      >
                        <p className="text-2xl font-bold">1000</p>
                        {/* <img src={CoinsMd} loading="lazy" alt="" /> */}
                        <p className="text-sm">Doubloons</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleOpenStore()}
                        className="bg-primary-700 hover:bg-primary-900 rounded p-4 items-center text-white"
                      >
                        <p className="text-2xl font-bold">1000</p>
                        {/* <img src={CoinsMd} loading="lazy" alt="" /> */}
                        <p className="text-sm">Doubloons</p>
                      </button>
                    )}
                  </div>

                  <div className="h-full shadow-md">
                    {balance > 2000 ? (
                      <button
                        onClick={() => handleTip(2000, activePerson.id)}
                        className="bg-primary-700 hover:bg-primary-900 rounded p-4 items-center text-white"
                      >
                        <p className="text-2xl font-bold">2000</p>
                        {/* <img src={CoinsLg} loading="lazy" alt="" /> */}
                        <p className="text-sm">Doubloons</p>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleOpenStore()}
                        className="bg-primary-700 hover:bg-primary-900 rounded p-4 items-center text-white"
                      >
                        <p className="text-2xl font-bold">2000</p>
                        <p className="text-sm">Doubloons</p>
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </div>
      
      <AssetGrid />
    </>
  );
};

export default Profile;
