import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIconSvg } from "../common/Icons";
// import { Button } from "../common/Button";
import { Toast } from "../common/Toast";
import { useSelector, useDispatch } from "react-redux";
import {
  setSlideout,
  // setModal,
  // setActiveModal,
} from "../../store/layout/actions";
import store from "../../store";
export type RootState = ReturnType<typeof store>;

type SlideoverProps = {
  children?: React.ReactNode;
};

const Slideover = React.memo(({ children }: SlideoverProps) => {
  const cancelButtonRef = useRef(null);
  // const balance = useSelector(
  //   (state: RootState) => state.people.self.doubloonBalance
  // );
  const isOpen = useSelector(
    (state: RootState) => state.layout.isSlideoutVisible
  );
  // const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const isToastVisible = useSelector(
    (state: RootState) => state.layout.isToastVisible
  );
  // const { online } = useSelector((state: RootState) => state.offline);

  const dispatch = useDispatch();

  // const handleDoubloonButton = () => {
  //   dispatch(setModal(true));
  //   dispatch(setActiveModal("BUY_DOUBLOONS"));
  // };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 overflow-hidden"
          open={isOpen}
          onClose={() => dispatch(setSlideout(false))}
          initialFocus={cancelButtonRef}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            {/* Toasts go here and the Landing because slideout is assertive about being on top */}
            {isToastVisible && <Toast />}

            <div className="fixed inset-y-0 right-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-100 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-100 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md overscroll-none">
                  <div className="h-full flex flex-col bg-white shadow-xl">
                    <div className="flex-1 h-0 overflow-y-auto">
                      <div className="pb-3 px-6 bg-primary-900">
                        <div className="flex items-end justify-end">
                        
                          <div className="ml-3 h-7 flex items-end text-white z-50">
                            <button
                              className="focus:outline-none"
                              onClick={() => dispatch(setSlideout(false))}
                              ref={cancelButtonRef}
                            >
                              <XIconSvg />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="-mt-10 relative flex-1 bg-primary-900">
                        {/* Replace with content */}
                        {children}
                        {/* /End replace */}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});

export default Slideover;
