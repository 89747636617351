import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import Post from "../../components/Post/Post";
import { getReputation } from "../../services/xpService";
import { FeedPostType } from "../../types";
import { getFeed, getPublicFeed } from "../../store/files/actions";

export type RootState = ReturnType<typeof store>;

const Posts = () => {
  const feed = useSelector((state: RootState) => state.files.feedFiles);
  const allFiles = useSelector((state: RootState) => state.files.byId);
  const allPeople = useSelector((state: RootState) => state.people.byId);
  const token = useSelector((state: RootState) => state.auth.token);
  const [feedPosts, setFeedPosts] = useState<Array<FeedPostType>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    token ? dispatch(getFeed(token)) : dispatch(getPublicFeed());
  }, [token, dispatch]);

  useEffect(() => {
    let feedPostsArray: Array<any> = [];
    feed.map((post) => {
      const file = allFiles[post];
      if (file) {
        const person = allPeople[file.person_id];
        // Get Post Rep
        const reputation = file.reputation;
        // Get Person Rep
        if (person) {
          const personRep = getReputation(person.xp_asset_balance);
          const bgColor = "bg-" + personRep.color + "-700";
          const ringColor = "ring-" + personRep.color + "-400";
          const textColor = "text-" + personRep.color + "-700";

          const feedObject = {
            target_id: file.person_id,
            name: person.name,
            subtitle: "Auction ends in 3 days",
            mdHqUrl: file.md_hq_url,
            mdLqUrl: file.md_lq_url,
            lgHqUrl: file.lg_hq_url,
            lgLqUrl: file.lg_lq_url,
            blUrl: file.bl_url,
            person_photo_url: person.photo_url,
            amount: file.bid,
            targetPersonBgColor: bgColor,
            targetPersonRingColor: ringColor,
            target_post_reputation: reputation,
            targetPersonXp: person.xp_asset_balance,
            targetPersonClassWidth: personRep.classWidth,
            targetPersonTextColor: textColor,
          };
          feedPostsArray.push(feedObject);
        }
      }

      return true;
    });
    setFeedPosts(feedPostsArray);
  }, [feed, allFiles, allPeople]);

  return (
    <>
      {feedPosts.map((post, idx) => {
        return (
            <div key={idx} className="p-2">
              <Post
                target_id={post.target_id}
                name={post.name}
                subtitle={post.subtitle}
                person_photo_url={post.person_photo_url}
                amount={post.amount}
                mdHqUrl={post.mdHqUrl}
                mdLqUrl={post.mdLqUrl}
                lgHqUrl={post.lgHqUrl}
                lgLqUrl={post.lgLqUrl}
                blUrl={post.blUrl}
                target_post_reputation={post.target_post_reputation}
                targetPersonBgColor={post.targetPersonBgColor}
                targetPersonRingColor={post.targetPersonRingColor}
                targetPersonXp={post.targetPersonXp}
                targetPersonTextColor={post.targetPersonTextColor}
                targetPersonClassWidth={post.targetPersonClassWidth}
              />
            </div>
        );
      })}
    </>
  );
};

export default Posts;
