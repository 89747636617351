import axios from 'axios';
import {
  USER_EXISTS,
} from '../constants/errors';
import { deleteLocalDatabase } from './storageService';
import {
  VALIDATE_USERNAME_ENDPOINT,
  VALIDATE_ASSETNAME_ENDPOINT
} from '../constants/endpoints';

export const validateUsername = async (querystring: string) => {
  const queryObject = {
    searchquery: querystring
  }
  return axios(VALIDATE_USERNAME_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: queryObject,
  })
  .then(({ data }) => {
    return data})
  .catch((error) => {
    console.error(error);
    throw new Error(USER_EXISTS);
  });
}

export const validateAssetName = async (querystring: string) => {
  const queryObject = {
    searchquery: querystring
  }
  return axios(VALIDATE_ASSETNAME_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: queryObject,
  })
  .then(({ data }) => {
    return data})
  .catch((error) => {
    console.error(error);
    throw new Error(USER_EXISTS);
  });
}


export const logoutUser = () => {
  localStorage.clear();
  sessionStorage.clear();
  document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  return deleteLocalDatabase('firebaseLocalStorageDb')
};
