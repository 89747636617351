import { FileType } from '../../types';
export interface FileReducerType {
    byId: { [id: string]: FileType };
    activeReps: Array<number>;
    activeFiles: Array<string>;
    feedFiles: Array<string>;
    gridFiles: Array<string>;
}

export const FILE_ADD = 'FILE_ADD';
export const FILE_ADD_SUCCESS = 'FILE_ADD_SUCCESS';
export const FILE_ADD_ERROR = 'FILE_ADD_ERROR';
export const FILE_ACTIVE_REPS = 'FILE_ACTIVE_REPS';
export const FILE_ADD_ACTIVE_FILE = 'FILE_ADD_ACTIVE_FILE';
export const FILE_CLEAR_ACTIVE_FILES = 'FILE_CLEAR_ACTIVE_FILES';
export const FILE_ADD_GRID_FILE = 'FILE_ADD_GRID_FILE';
export const FILE_ADD_FEED_FILE= 'FILE_ADD_FEED_FILE';
export const FILE_FEED = 'FILE_FEED';
export const FILE_FEED_SUCCESS = 'FILE_FEED_SUCCESS';