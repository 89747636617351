import React, { useState } from "react";
import { RadioGroup } from ".";
import { CashIcon } from "../common/Icons";
import { SettingsInput } from "../../screens/SelfProfile/index";
import { addFiles, clearActiveFiles } from "../../store/files/actions";
import { normalizeArrayByKey } from "../../services/utils";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { Image as Limage } from "../common/Image";
import { Button } from "../common/Button";
import DoubloonSpinner from "../common/Icons/Coins/Sprites/doubloon-spinning.svg";
import FileUpload from "./FileUpload";
import { setModal } from "../../store/layout/actions";

export type RootState = ReturnType<typeof store>;

const Post = () => {
  const dispatch = useDispatch();
  const activeFiles = useSelector(
    (state: RootState) => state.files.activeFiles
  );
  const allFiles = useSelector((state: RootState) => state.files.byId);
  const activeReps = useSelector((state: RootState) => state.files.activeReps);
  const selfId = useSelector((state: RootState) => state.people.self.id);

  const [activeLoadingMessage, setActiveLoadingMessage] =
    useState("Upload Image");
  const [isLoading, setIsLoading] = useState(false);
  const [bidValue, setBidValue] = useState(0);
  const token = useSelector((state: RootState) => state.auth.token);

  const handlePostButton = () => {
    const id = allFiles[activeFiles[0]].id;
    // Get Post Rep
    const lowestRep = Math.min(...activeReps);
    let reputation = "NEUTRAL";
    lowestRep === 0 && (reputation = "NEUTRAL");
    lowestRep === 1 && (reputation = "FRIENDLY");
    lowestRep === 2 && (reputation = "HONORED");
    lowestRep === 3 && (reputation = "REVERED");
    lowestRep === 4 && (reputation = "EXALTED");
    const fileObject = {
      id: id,
      reputation: reputation,
      bid: bidValue,
      person_id: selfId,
      status: "POSTED",
    };
    const normalizedFiles = normalizeArrayByKey([fileObject], "id");
    token && dispatch(addFiles([fileObject], normalizedFiles, token));
    dispatch(clearActiveFiles());
    setIsLoading(false);
    setActiveLoadingMessage("Upload Image");
    dispatch(setModal(false));
  };

  const handleBidChange = (e: any) => {
    setBidValue(e.target.value);
  };

  const url = allFiles[activeFiles[0]]?.sq_url;

  return (
    <div className="px-4 py-4">
      {isLoading ? (
        <div>
          <p className={"text-lg font-bold text-primary-700 animate-pulse"}>
            {activeLoadingMessage}
          </p>
          <object
            type="image/svg+xml"
            data={DoubloonSpinner}
            className="flex mx-auto items-center h-16 w-16 m-4"
          >
            svg-animation
          </object>
        </div>
      ) : (
        <p className={"text-lg font-bold text-primary-700"}>
          {activeLoadingMessage}
        </p>
      )}
      <div className="">
        {activeFiles.length >= 1 ? (
          <div className="pt-2">
            <div className="grid grid-cols-2 w-full flex items-center border-2 rounded-md px-6 py-6">
              <div className="justify-self-start">
                {url && (
                  <Limage
                    url={url}
                    className="w-32 h-32 rounded-md"
                    onLoad={() => {
                      return null;
                    }}
                  />
                )}
              </div>
              <div className="justify-self-end">
                <Button
                  label="POST"
                  onClick={() => handlePostButton()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {!isLoading && (
              <FileUpload
                setIsLoading={setIsLoading}
                setActiveLoadingMessage={setActiveLoadingMessage}
                bidValue={bidValue}
              />
            )}
          </div>
        )}

        <div className="pt-4">
          <SettingsInput
            label="Starting Bid"
            fieldName="startingBid"
            error={false}
            Icon={<CashIcon />}
            onChange={(e: any) => {
              handleBidChange(e);
            }}
            value={bidValue.toString()}
          />
        </div>
        <div className="pt-4">
          <RadioGroup />
        </div>
      </div>
    </div>
  );
};

export default Post;
