import { Reducer } from 'redux';
import {
  AUTH_LOGIN_BEGIN,
  AUTH_API_LOGIN_ERROR,
  AUTH_API_LOGIN_SUCCESS,
  AUTH_LOGOUT_BEGIN,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_SUCCESS,
  AUTH_VALIDATION_ERROR,
  AUTH_TOKEN_NOT_FOUND_ERROR,
  AUTH_FIREBASE_LOGIN_ERROR,
  AUTH_FIREBASE_LOGIN_SUCCESS,
  AuthReducerTypes,
} from './constants';

const defaultState = {
  id: '0',
  isAuthLoading: false,
  token: null,
  isLoggedIn: false,
  email: null,
  tokenError: true,
  stripe_account_link: '',
  emergency_logout: false,
  isFirstTime: true,
};

const authReducer: Reducer<AuthReducerTypes> = (
  state = defaultState,
  action: { type: String; payload?: any }
) => {
  switch (action.type) {
    case AUTH_LOGOUT_BEGIN: {
      return {
        ...state,
        isAuthLoading: true,
        isLoggedIn: true,
      };
    }
    case AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        isAuthLoading: false,
        isLoggedIn: false,
        id: '0',
        token: null,
        isFirstTime: true,
      }
    }
    case AUTH_LOGOUT_ERROR: {
      return {
        ...state,
        isAuthLoading: false,
        isLoggedIn: true,
      }
    }
    case AUTH_LOGIN_BEGIN: {
      return {
        ...state,
        isAuthLoading: true,
        isLoggedIn: false,
        tokenError: false,
      };
    }
    case AUTH_API_LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthLoading: false,
        token: action.payload.success.user.api_token,
        isLoggedIn: true,
        email: action.payload.success.user.email,
        id: action.payload.success.user.id,
        tokenError: false,
        emergency_logout: false,
        isFirstTime: false,
      }
    }
    case AUTH_API_LOGIN_ERROR: {
      return {
        ...state,
        id: '0',
        isAuthLoading: false,
        token: null,
        isLoggedIn: false,
        email: null,
        tokenError: true,
        emergency_logout: true,
        isFirstTime: true,
      }
    }
    case AUTH_FIREBASE_LOGIN_SUCCESS: {
      window.localStorage.setItem('token', action.payload.success.user.api_token);
      return {
        ...state,
        isAuthLoading: false,
        token: action.payload.success.user.api_token,
        isLoggedIn: true,
        id: action.payload.success.user.id,
        stripe_account_link: action.payload.success.user.profile.stripe_account_link,
        emergency_logout: false,
        isFirstTime: false,
      }
    }
    case AUTH_FIREBASE_LOGIN_ERROR: {
      return {
        ...state,
        id: '0',
        isAuthLoading: false,
        token: null,
        isLoggedIn: false,
        emergency_logout: true,
        isFirstTime: true,
      }
    }
    case AUTH_TOKEN_NOT_FOUND_ERROR: {
      return {
        ...state,
        id: '0',
        isAuthLoading: false,
        token: null,
        isLoggedIn: false,
        email: null,
        emergency_logout: true,
        isFirstTime: true,
      }
    }
    case AUTH_VALIDATION_ERROR: {
      return {
        ...state,
        isAuthLoading: false,
      }
    }
    default:
      return state;
  }
};

export default authReducer;