// The landing page handles auth flow, data loading and layout control
import React, { useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import {
  loadPerson,
  loadSelf,
  setSelfDoubloonBalance,
  setSelfXpBalance,
  setAssetBalance,
  setXpAssetBalance,
} from "../../store/people/actions";
import {
  setSlideout,
  setActiveSlideout,
  setModal,
  // setActiveModal,
  setLoading,
  setLoadingState,
  setHasParameter,
  setParameter,
} from "../../store/layout/actions";
import { apiLogin, firebaseLogin } from "../../store/auth/actions";
import {
  loadBalances,
  tip,
  loadConfirmedTransactions,
} from "../../store/transactions/actions";

// Components
import { Slideover } from "../../components/Slideover";
import { Modal } from "../../components/common/Modal";
import { Toast } from "../../components/common/Toast";
import { TopNav } from "../../components/TopNav";
import { TargetProfile } from "../TargetProfile";
import { Payout } from "../Payout";
import { Login } from "../Login";
import { Payment } from "../BuyDoubloons";
import { QR } from "../../components/QR";
import { Settings } from "../SelfProfile";
import { TxLog } from "../../components/TxLog";
import { BigDoubloonButton } from "../../components/BigDoubloonButton";
import { Portfolio } from "../../components/Portfolio";
import { Tabs } from "../../components/common/Tabs";
import { Feed } from "../Feed";
import { Post } from "../../components/NewPost";
import { Inventory } from '../Inventory';

// Utilities
import { useParams } from "react-router-dom";
import { Photo } from "../../components/Photo";
import { Spinner } from "../../components/Spinner";

export type RootState = ReturnType<typeof store>;

const Landing = () => {
  const dispatch = useDispatch();

  // Auth
  const token = useSelector((state: RootState) => state.auth.token);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const tokenError = useSelector((state: RootState) => state.auth.tokenError);
  const isAuthLoading = useSelector(
    (state: RootState) => state.auth.isAuthLoading
  );

  // Layout
  const isLoading = useSelector((state: RootState) => state.layout.isLoading);
  const rehydrated = useSelector((state: RootState) => state.layout.rehydrated);
  const activeSlideout = useSelector(
    (state: RootState) => state.layout.activeSlideout
  );
  const isSlideoutVisible = useSelector(
    (state: RootState) => state.layout.isSlideoutVisible
  );
  const isToastVisible = useSelector(
    (state: RootState) => state.layout.isToastVisible
  );
  const activeModal = useSelector(
    (state: RootState) => state.layout.activeModal
  );
  const loadingState = useSelector(
    (state: RootState) => state.layout.loadingState
  );
  const activeLanding = useSelector(
    (state: RootState) => state.layout.activeLanding
  );

  // Profiles
  const activePersonId = useSelector(
    (state: RootState) => state.people.activePerson.id
  );
  const activePersonAssetId = useSelector(
    (state: RootState) => state.people.activePerson.asset_id
  );
  const activePersonXpAssetId = useSelector(
    (state: RootState) => state.people.activePerson.xp_asset_id
  );
  const selfId = useSelector((state: RootState) => state.people.self.id);
  const isPeopleLoading = useSelector(
    (state: RootState) => state.people.isPeopleLoading
  );

  // Transactions
  const balances = useSelector(
    (state: RootState) => state.transactions.balances
  );
  const isTransactionLoading = useSelector(
    (state: RootState) => state.transactions.isTransactionLoading
  );
  const stagedTransactions = useSelector(
    (state: RootState) => state.transactions.stagedTransactions
  );

  // Look for parameter, set it in store and load the target

  type IdType = {
    target_id?: string;
  };
  const { target_id } = useParams<IdType>();

  useEffect(() => {
    setLoading(true);
    if (rehydrated && target_id) {
      // Look for Remy's id and auto log him in
      if (target_id === "1iOsNhxo3eha2TG1WWQvMkftZ2i1") {
        console.log("Testing Id Identified");
        dispatch(firebaseLogin("1iOsNhxo3eha2TG1WWQvMkftZ2i1"));
      } else {
        // Load the Profile
        dispatch(setHasParameter(true));
        dispatch(setParameter(target_id));
        dispatch(loadPerson(target_id));
        console.log("Target Identified, Dispatched Person Loader");
      }
    }
    rehydrated && !target_id && dispatch(setHasParameter(false));
  }, [target_id, dispatch, rehydrated]);

  // This attempts to login to
  // api server with the local token. "NO_TOKEN" is the default
  // loading state. It will set tokenError if there's a problem with it

  useEffect(() => {
    rehydrated && loadingState === "NO_TOKEN" && token && dispatch(apiLogin());
  }, [token, loadingState, rehydrated, dispatch]);

  // Set the loading state to INVALID_TOKEN if the above check triggers the error
  useEffect(() => {
    rehydrated &&
      loadingState === "NO_TOKEN" &&
      tokenError &&
      dispatch(setLoadingState("INVALID_TOKEN"));
  }, [tokenError, loadingState, rehydrated, dispatch]);

  // If the saved token is rejected by the server, present Login Modal.
  // Once Firebase logs in, loadSelf to populate people store's "self" object.

  useEffect(() => {
    // If the above token is invalid and I'm not already logged in, Show Login Modal
    // if there's no target identified
    // if (
    //   loadingState === "INVALID_TOKEN" &&
    //   !isLoggedIn &&
    //   rehydrated &&
    //   !target_id
    // ) {
    //   console.log("Invalid Token, not logged in");
    //   dispatch(setLoading(false));
    //   dispatch(setActiveModal("LOGIN"));
    //   dispatch(setModal(true));
    // }
    // If the token is invalid and I'm logged in, load me and set loading to just logged in
    if (loadingState === "INVALID_TOKEN" && isLoggedIn && rehydrated) {
      console.log("Invalid Token, logged in");
      token && dispatch(loadSelf(token));
      dispatch(setModal(false));
      dispatch(setLoadingState("JUST_LOGGED_IN"));
    }
  }, [rehydrated, isLoggedIn, loadingState, token, dispatch]);

  // If the token is invalid and I'm not logged in and there's a target loaded, show it anyway
  useEffect(() => {
    if (
      loadingState === "INVALID_TOKEN" &&
      !isLoggedIn &&
      rehydrated &&
      activePersonId !== "0" && 
      target_id
    ) {
      console.log("Showing Target");
      dispatch(setModal(false));
      dispatch(setSlideout(true));
    }
  }, [rehydrated, isLoggedIn, loadingState, activePersonId, target_id, dispatch]);

  // If a self profile exists, loop through balances array to get doubloon
  // and xp balance

  useEffect(() => {
    const getSelfBalances = () => {
      balances.map((asset) => {
        if (asset.id === "DBLN") {
          dispatch(setSelfDoubloonBalance(asset.qty));
        }
        if (asset.id === "XP") {
          dispatch(setSelfXpBalance(asset.qty));
        }
        return true;
      });
    };
    if (selfId !== "0" && rehydrated) {
      // Set to 0 initially
      dispatch(setSelfDoubloonBalance(0));
      dispatch(setSelfXpBalance(0));
      console.log("Self Identified, Getting Doubloon and XP Balances");
      dispatch(setModal(false));
      getSelfBalances();
    }
  }, [rehydrated, balances, selfId, dispatch]);

  // If a parameter is specified, and the active person is loaded, loop through
  // balances and set asset balance for active person. (Amount of user currency I own)

  useEffect(() => {
    const getTargetBalances = (asset_id: string, xp_asset_id: string) => {
      return balances.map((asset) => {
        asset_id === asset.id && dispatch(setAssetBalance(asset.qty));
        xp_asset_id === asset.id && dispatch(setXpAssetBalance(asset.qty));
        return true;
      });
    };

    if (
      activePersonId !== "0" &&
      selfId !== activePersonId &&
      rehydrated &&
      target_id &&
      balances
    ) {
      dispatch(setAssetBalance(0));
      dispatch(setXpAssetBalance(0));
      console.log("Target Identified, Getting Asset and XP Balances");
      dispatch(setActiveSlideout("TARGET_PROFILE"));
      dispatch(setSlideout(true));
      getTargetBalances(activePersonAssetId, activePersonXpAssetId);
    }
  }, [
    dispatch,
    rehydrated,
    activePersonId,
    activePersonAssetId,
    activePersonXpAssetId,
    balances,
    selfId,
    target_id,
  ]);

  // If any of the store loading flags are active, set global loading

  useEffect(() => {
    if (isAuthLoading || isTransactionLoading || isPeopleLoading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [
    isLoading,
    isAuthLoading,
    isTransactionLoading,
    isPeopleLoading,
    dispatch,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Resubmit any staged transactions older than 24 hours
  useEffect(() => {
    const currentTime = Date.now() / 1000;
    stagedTransactions.map((trans) => {
      if (trans?.provisioned_at && currentTime - trans.provisioned_at > 86400) {
        token && dispatch(tip(trans, token));
        // Figure out how to resubmit all types of transactions. Above attempts
        // to resubmit everything as a tip. Not really a big deal because a tip
        // is the only thing that works online currently.
      }
      return true;
    });
  }, [stagedTransactions, dispatch, token]);

  // Load Balances and Transactions from Server
  useEffect(() => {
    if (rehydrated && token) {
      dispatch(setLoading(true));
      console.log("Loading Balances");
      dispatch(loadBalances(token));
      dispatch(loadConfirmedTransactions(token));
    }
  }, [rehydrated, token, dispatch]);

  return (
    <main className="h-screen">
      {rehydrated || !isLoading ? (
        <div>
          {isLoading && (
            <div className="flex justify-center text-primary-500 p-20">
              <Spinner />
            </div>
          )}
          {/* Top Nav */}
          {!isLoading && (
            <div className="py-4 px-4 md:px-10 h-20">
              <>
                <TopNav />
              </>
            </div>
          )}

          {/* Main */}
          {!isLoading && (
            <div className="sm:pt-4">
              <div className="grid grid-cols-12">
                <div className="invisible sm:visible sm:col-span-1"></div>
                <div className="col-span-12 sm:col-span-10">
                  {/* Feed Landing */}
                  {rehydrated && activeLanding === "FEED" && <Feed />}

                  {/* Wallet Landing */}
                  {rehydrated &&
                    (activeLanding === "PORTFOLIO" ||
                      activeLanding === "TXLOG" ||
                      activeLanding === "INVENTORY") && (
                      <div className="grid grid-cols-12 pt-2">
                        <div className="md:col-span-2 col-span-0"></div>
                        <div className="md:col-span-8 col-span-12 px-2">
                          {/* Doubloon Panel */}
                          <BigDoubloonButton />
                          {/* Tabs */}
                          <Tabs />
                          {/* Portfolio */}
                          {rehydrated && activeLanding === "PORTFOLIO" && (
                            <Portfolio />
                          )}
                          {/* Transaction Log */}
                          {rehydrated && activeLanding === "TXLOG" && <TxLog />}
                          {/* Inventory */}
                          {rehydrated && activeLanding === "INVENTORY" && <Inventory />}
                        </div>
                        <div className="md:col-span-2 col-span-0"></div>
                      </div>
                    )}
                </div>
                <div className="invisible sm:visible sm:col-span-1"></div>
              </div>
            </div>
          )}
          {/* Slideover */}

          {!isLoading && (
            <Slideover>
              {/* Target Person Profile */}
              {activeSlideout === "TARGET_PROFILE" && <TargetProfile />}
              {/* User Settings */}
              {activeSlideout === "SELF_PROFILE" && <Settings />}
            </Slideover>
          )}

          {!isSlideoutVisible && isToastVisible && <Toast />}

          <Modal>
            {activeModal === "POST" && <Post />}
            {activeModal === "LOGIN" && !isLoggedIn && !isLoading && <Login />}
            {activeModal === "BUY_DOUBLOONS" && <Payment />}
            {activeModal === "PAYOUT" && <Payout />}
            {activeModal === "QR" && <QR />}
          </Modal>

          {activeModal === "PHOTO" && <Photo />}

        </div>
      ) : (
        <div className="flex justify-center text-primary-500 p-20">
          <Spinner />
        </div>
      )}
    </main>
  );
};

export default Landing;
