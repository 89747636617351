import React from 'react';
import { Button } from "../../components/common/Button";
import { useSelector, useDispatch } from 'react-redux'
import store from "../../store";
import { exchangeActivate, transaction } from '../../store/transactions/actions';
import { setLoading, setModal, setSlideout, setActiveToast, setToast } from '../../store/layout/actions';
import * as yup from "yup";

export type RootState = ReturnType<typeof store>;

const Payout = () => {

  const balance = useSelector((state: RootState) => state.people.self.doubloonBalance);
  const spinning = useSelector((state: RootState) => state.layout.isLoading);
  const transactionAmount = useSelector((state: RootState) => state.transactions.transactionAmount);
  const token = useSelector((state: RootState) => state.auth.token);

  const dispatch = useDispatch();

    // Sell Page Functions
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.currentTarget.value;
      const transactionObject = {
        amount: currentValue,
      };
      const digitsOnly: any = (value: string) =>
        /^\d*[.{1}\d*]\d*$/.test(value) || value.length === 0;
      const transactionSchema = yup.object().shape({
        amount: yup
          .string()
          .test("inputEntry", "The field should have digits only", digitsOnly),
      });
      transactionSchema.isValid(transactionObject).then((res) => {
        res && dispatch(exchangeActivate(currentValue));
      });
    };
  
    const handleClick = (exchangeValue: number) => {
      setLoading(true);
      if (token) {
        const transactionObject = {
          id: require("crypto").randomBytes(64).toString('hex'),
          action: "EXCHANGE",
          amount: exchangeValue,
          token: token,
          status: "PENDING",
        };

  
        const transactionSchema = yup.object().shape({
          amount: yup
            .number()
            .required()
            .positive()
            .integer()
            .lessThan(balance + 1)
            .min(100)
        });
  
        transactionSchema.isValid(transactionObject).then((res) => {
          dispatch(setLoading(true));
          dispatch(setSlideout(false));
          dispatch(setModal(false));
          if (res) {
            dispatch(transaction(transactionObject));
            dispatch(setActiveToast("SELL"));
            dispatch(setToast());
            dispatch(setLoading(false));
          } else {
            dispatch(setActiveToast("INSUFFICIENT_FUNDS"));
            dispatch(setToast());
            dispatch(setLoading(false));
          }
        });
      }
    };

  return (
    <div className="bg-white px-4">
      <div className="mx-auto md:px-6 pt-5 pb-5 w-full text-lg text-bold max-w-xl">
        <label
          htmlFor="transactionAmount"
          className="block text-sm font-medium text-gray-700"
        >
          Doubloons to Sell
        </label>
        <div className="rounded-md shadow-sm">
          <input
            type="text"
            name="transactionAmount"
            id="transactionAmount"
            className="focus:ring-primary-500 focus:border-primary-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            onChange={(evt: any) => handleChange(evt)}
            value={transactionAmount || ""}
          />
        </div>
      </div>
      <div className="mx-auto md:px-6 pt-5 pb-5 w-full text-lg text-bold max-w-xl">
        <div className="text-sm font-medium text-gray-700">
          Transferring $
          {transactionAmount ? (transactionAmount / 100).toFixed(2) : 0} to linked
          account
        </div>
        <div className="py-4 grid grid-cols-2">
          <div className="col-span-1">

          </div>
          <div className="">
          <Button
            onClick={() => handleClick(transactionAmount)}
            variant={transactionAmount <= balance ? "primary" : "disabled"}
            label={`Payout`}
            spinning={spinning}
          />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Payout;
