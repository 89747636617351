import { loadStripe } from "@stripe/stripe-js";
import { STORE_INSUFFICIENT_FUNDS } from "../../constants/copy";
import { BASE_WEB_URL } from "../../constants/endpoints";
import DoubloonPanel from "./DoubloonPanel";
import { setModal } from '../../store/layout/actions'
import { buy } from '../../store/transactions/actions';
import { useSelector, useDispatch } from 'react-redux'
import store from "../../store";
import { BuyType } from '../../types';
export type RootState = ReturnType<typeof store>;

const Payment = () => {

  const person = useSelector((state: RootState) => state.people.self);
  const target = useSelector((state: RootState) => state.layout.parameter);
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch()

  const balance = person.doubloonBalance;

  const stripePromise = loadStripe(
    "pk_test_51J9HiIAgNAQCF9KlCnKxsIUv1paMzvdztVJ3zqT290kL8G4ACNFcgcOAun4TLszpo9xTqFH9qlxASTcemvlqSdh600z6Z2rICu"
  );

  let successUrl = BASE_WEB_URL;
  let cancelUrl = BASE_WEB_URL;

  if (target) {
    successUrl = successUrl + "/" + target;
  }
  if (target) {
    cancelUrl = cancelUrl + "/" + target;
  }

  const handlePayment = async (paymentId: string) => {

    const idem = 'p' + require("crypto").randomBytes(8).toString('hex');

    const buyObject: BuyType = {
      id: idem,
      provisioned_at: (Date.now() / 1000),
      status: 'PROVISIONED',
    };

    token && dispatch(buy(buyObject, token));

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    if (stripe && successUrl && cancelUrl) {
      dispatch(() => setModal(false));

      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: paymentId,
            quantity: 1,
          },
        ],
        mode: "payment",
        successUrl: successUrl,
        cancelUrl: cancelUrl,
        customerEmail: person.email,
        clientReferenceId: idem,
      });
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="py-5 px-2 sm:px-6">
        <>
          {balance <= 0 && <p>{STORE_INSUFFICIENT_FUNDS}</p>}
          <DoubloonPanel
            amt="100"
            price="$1.60"
            handlePayment={handlePayment}
            paymentId="price_1JUGzYAgNAQCF9KlXhPVAJXS"
            testid='doubloon-panel-test'
          />

          <DoubloonPanel
            amt="500"
            price="$8"
            handlePayment={handlePayment}
            paymentId="price_1JUH4vAgNAQCF9KlDMFleuqc"
          />

          <DoubloonPanel
            amt="1625"
            price="$25"
            handlePayment={handlePayment}
            savings="5% Discount"
            paymentId="price_1JUHBZAgNAQCF9Klij13vXmt"
          />

          <DoubloonPanel
            amt="4650"
            price="$65"
            handlePayment={handlePayment}
            savings="8% Discount"
            paymentId="price_1JUHDkAgNAQCF9Kle7BygBrJ"
          />
        </>
      </div>
    </>
  );
};

export default Payment;
