import { useSelector, useDispatch } from "react-redux";
import { setModal, setActiveModal } from "../../store/layout/actions";
import store from "../../store";
export type RootState = ReturnType<typeof store>;

const BigDoubloonButton = () => {
  const { online } = useSelector((state: RootState) => state.offline);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { self } = useSelector((state: RootState) => state.people);

  const dispatch = useDispatch();

  const handleDoubloonButton = () => {
    if (isLoggedIn) {
      dispatch(setActiveModal("BUY_DOUBLOONS"));
    } else {
      dispatch(setActiveModal("LOGIN"));
    }
    dispatch(setModal(true));
  };

  return (
    <>
      {/* {isLoggedIn && ( */}
      <div className="justify-center flex pb-10">
        <button
          onClick={() => online && handleDoubloonButton()}
          className="p-5 w-full border-2 shadow-lg rounded-xl focus:outline-none"
          data-testid="button-test"
        >
          <div className="pr-4">
            <div className="font-thin text-lg">DOUBLOONS</div>
            <div className="font-bold text-gray-500 -mt-2 text-8xl">
              <p>{self.doubloonBalance}</p>
            </div>
          </div>
        </button>
      </div>
      {/* )} */}
    </>
  );
};

export default BigDoubloonButton;
