// First time user, launches tutorial
export const TUTORIAL_ONE = "It looks like it’s your first time here. Let’s get acquainted!";
export const TUTORIAL_TWO = "Our artists sell digital tokens that increase your reputation with the artist. Higher levels of reputation unlock exclusive perks. "
export const TUTORIAL_THREE = "You purchase tokens from artists with Doubloons, Libertalia’s custom cryptocurrency. Artists can redeem Doubloons for cash or reinvest them back into the community by supporting other artists."
export const TUTORIAL_FOUR = "We’re glad you’re here, so let’s get you signed in!"

// First time after logging in goes to the store
export const STORE_NEW_DEVICE = "You exchange doubloons for artist tokens. The more tokens you have, the higher your rep with that artist.";
export const STORE_NO_FUNDS = "Looks like you're out of Doubloons. Let's fix that!";

// After purchasing doubloons or dismissing the store
export const PROFILE_NEW_DEVICE = "This is the artist profile where you can visit their social media profiles and track your rep.";
// Next
export const TIP_NEW_DEVICE = "When you tip an artist with Doubloons, you get artist tokens good for exclusive rewards from the artist!";


// Local token detected, but invalid
export const LOGIN_EXISTING_DEVICE = "Let's Sign In!";
// After login, if no doubloons, go to STORE_NO_FUNDS then profile
// If tipping without enough funds
export const STORE_INSUFFICIENT_FUNDS = "";
