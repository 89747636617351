import React from 'react';

type PostBottomPanelType = {
    targetPersonTextColor: string,
    amount: number,
    targetPersonBgColor: string,
}

const PostBottomPanel = (props: PostBottomPanelType) => {
    const {
        targetPersonTextColor,
        targetPersonBgColor,
        amount
    } = props;
    return (
        <div className="pt-3">
        <div className="grid grid-cols-2">
          <div className="grid grid-rows-2 flex space-around">
            <p className="text-xs flex self-start justify-self-start text-gray-500 text-xs ">
              HIGH BID
            </p>
            <p
              className={
                "-mt-3 text-lg flex self-start justify-self-start font-bold items-start " +
                targetPersonTextColor
              }
            >
              {amount}
            </p>
          </div>
          <div className="flex place-self-end">
            <div className="">
              <input
                className="w-20 mr-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                type="text"
                value={amount}
                onChange={() => {
                  return null;
                }}
              />
            </div>
            <div className="">
              <button
                className={
                  "inline-flex items-center px-4 py-2 border border-transparent shadow-lg font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm " +
                  targetPersonBgColor
                }
              >
                BID
              </button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default PostBottomPanel;