import React from "react";
import { useDispatch } from "react-redux";
import waves from "./waves.webp";

import { setModal, setActiveModal } from "../../store/layout/actions";

const LoginPanel = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setModal(true));
    dispatch(setActiveModal("LOGIN"));
  };

  return (
    <div
    className="bg-cover mx-2 sm:h-64 shadow-lg rounded-xl mb-6"
    style={{ backgroundImage: `url(${waves})` }}

    >
      <div
        className="
        shadow-lg sm:h-64 rounded-xl mb-3 pb-3 bg-opacity-30
        flex justify-center grid grid-rows-2 items-center bg-gray-900 sm:px-2
        "
      >
        <div className="text-5xl text-white font-bold py-8">GET PAID!</div>
        <button
          className="
            border-1 bg-primary-700 text-white font-bold px-4 py-1 rounded-md shadow-lg
            hover:bg-primary-900 text-2xl h-16 ring-white ring-2
            "
          onClick={() => handleClick()}
        >
          LOGIN
        </button>
      </div>
    </div>
  );
};

export default LoginPanel;
