import {
  FILE_ADD,
  // FileType,
  FILE_ACTIVE_REPS,
  FILE_ADD_ACTIVE_FILE,
  FILE_ADD_GRID_FILE,
  FILE_ADD_FEED_FILE,
  FILE_CLEAR_ACTIVE_FILES,
  FILE_ADD_SUCCESS,
  FILE_ADD_ERROR,
  FILE_FEED,
  FILE_FEED_SUCCESS
} from "./constants";
import { IMAGE_ENDPOINT, FEED_ENDPOINT, PUBLIC_FEED_ENDPOINT } from '../../constants/endpoints';
import { FileType } from '../../types';


export const addFiles = (files: Array<FileType>, normalizedFiles: Array<FileType>, token: string) => (dispatch: any) => {

    files.map(file => {
      dispatch({
        type: FILE_ADD,
        payload: { normalizedFiles },
        meta: {
          offline: {
            effect: {
              url: IMAGE_ENDPOINT,
              method: "POST",
              body: JSON.stringify(file),
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            commit: { type: FILE_ADD_SUCCESS, meta: { normalizedFiles } },
            rollback: { type: FILE_ADD_ERROR, meta: { normalizedFiles } },
          },
        },
      });
      return true;
    })

    return { success: true };
};

export const setActiveRep = (idxAry: Array<number>) => (dispatch: any) => {
  dispatch({ type: FILE_ACTIVE_REPS, payload: { idxAry } });
};

export const setActiveFiles = (id: string) => (dispatch: any) => {
  dispatch({ type: FILE_ADD_ACTIVE_FILE, payload: id });
}

export const setGridFiles = (id: string) => (dispatch: any) => {
  dispatch({ type: FILE_ADD_GRID_FILE, payload: id });
}

export const setFeedFiles = (id: string) => (dispatch: any) => {
  dispatch({ type: FILE_ADD_FEED_FILE, payload: id });
}

export const clearActiveFiles = () => (dispatch: any) => {
  dispatch({ type: FILE_CLEAR_ACTIVE_FILES });
}

export const getFeed = (token: string) => (dispatch: any) => {
  dispatch({
    type: FILE_FEED,
    payload: { token },
    meta: {
      offline: {
        effect: {
          url: FEED_ENDPOINT,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        commit: { type: FILE_FEED_SUCCESS, meta: { token } },
        rollback: { type: FILE_ADD_ERROR, meta: { token } },
      },
    },
  });
}

export const getPublicFeed = () => (dispatch: any) => {
  dispatch({
    type: FILE_FEED,
    payload: { },
    meta: {
      offline: {
        effect: {
          url: PUBLIC_FEED_ENDPOINT,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
        commit: { type: FILE_FEED_SUCCESS, meta: { } },
        rollback: { type: FILE_ADD_ERROR, meta: { } },
      },
    },
  });
}