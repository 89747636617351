import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
// import { ParrotIconSvg, BadgeCheckIcon } from "../common/Icons";
import {
  setHasParameter,
  setParameter,
  setSlideout,
  setActiveSlideout,
} from "../../store/layout/actions";

export type RootState = ReturnType<typeof store>;

const Portfolio = () => {
  const balances = useSelector(
    (state: RootState) => state.transactions.balances
  );
  const asset_name = useSelector(
    (state: RootState) => state.people.self.asset_name
  );
  const photo_url = useSelector(
    (state: RootState) => state.people.activePerson.photo_url
  )

  const dispatch = useDispatch();

  return (
    <>
      <ul className="">
        {balances &&
          balances?.map((transaction) => {
            transaction.id === "DBLN" && (transaction.name = "Doubloons");
            return (
              <div key={transaction.id}>
                {transaction.name !== null &&
                asset_name !== transaction.name ? (
                  <li
                    key={transaction.id}
                    className="rounded-md border-1 py-4 px-3 shadow-md cursor-pointer"
                    onClick={() => {
                      if (transaction.user_name) {
                        dispatch(setHasParameter(true));
                        dispatch(setParameter(transaction.user_name));
                        dispatch(setActiveSlideout("TARGET_PROFILE"));
                        dispatch(setSlideout(true));
                      }
                    }}
                  >
                    <div className="flex space-x-3">
                      <div className="flex-1 space-y-1">
                        <div className="flex items-center justify-between">
                        <div className="flex-shrink pr-2">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={photo_url}
                            alt=""
                          />
                          </div>
                          {/* <div className="flex-shrink pr-2">
                                {transaction.person_name === "Libertalia" ? (
                                  <ParrotIconSvg />
                                ) : (
                                  <img
                                    className="h-6 w-6 rounded-full"
                                    src={transaction.photo_url}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="text-yellow-500 pr-2">
                                <BadgeCheckIcon />
                              </div> */}
                          <div className="flex-grow">
                            <h3 className="text-sm font-medium">
                              {transaction.name}
                            </h3>
                            <p className="text-xs text-gray-500">
                              {transaction.person_name}
                            </p>
                          </div>

                          <div className="">
                            <div
                              className={
                                "w-20 flex justify-center text-sm rounded-md bg-secondary-500 text-white py-1 px-2"
                              }
                            >
                              {transaction.qty}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
      </ul>
    </>
  );
};

export default Portfolio;
