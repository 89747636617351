import React from "react";
import { useDispatch } from "react-redux";
import { setActiveModal, setActivePhoto } from "../../store/layout/actions";
import { Image } from "../../components/common/Image";
import { LockClosedIcon } from "../../components/common/Icons";
import { getReputationColor } from "../../services/xpService";

type ThumbnailPropsType = {
  square?: string;
  photo?: string;
  blur?: string;
  rep: string;
  isBlurred?: boolean;
};

const Thumbnail = (props: ThumbnailPropsType) => {
  const { square, photo, blur, rep, isBlurred } = props;

  const dispatch = useDispatch();

  const handlePhotoClick = (photo: string) => {
    dispatch(setActiveModal("PHOTO"));
    dispatch(setActivePhoto(photo));
  };

  return (
    <div className={`bg-${getReputationColor(
      rep
    )}-400 
    border-2 p-1 rounded-md shadow-md w-28 h-28
    `}>
      {!isBlurred ? (
        <button
          onClick={() => {
            const url = photo;
            url && handlePhotoClick(url);
          }}
        >
          {square && (
            <Image url={square} className="w-full h-full rounded-md" />
          )}
        </button>
      ) : (
        <div>
          {blur ? (
            <div
              className={`bg-${getReputationColor(
                rep
              )}-400 w-full h-full rounded-md text-white relative`}
            >
              <div className="absolute flex p-6">
                <LockClosedIcon />
              </div>
              <Image url={blur} className="w-full h-full rounded-md" />
            </div>
          ) : (
            <div className="bg-gray-100 w-full h-full rounded-md"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
