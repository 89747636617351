import React from "react";
import store from "../../store";
import { FeedPostType } from "../../types";
import { PostBottomPanel, PostPhoto, PostProfile } from ".";
import { XpMeter } from "../XpMeter";
import { getReputationColor } from "../../services/xpService";
import { useSelector } from "react-redux";    

export type RootState = ReturnType<typeof store>;

const Post = (props: FeedPostType) => {
  const {
    target_id,
    name,
    person_photo_url,
    amount,
    lgLqUrl,
    mdHqUrl,
    blUrl,
    target_post_reputation,
    targetPersonBgColor,
    targetPersonRingColor,
    targetPersonTextColor,
    targetPersonXp,
  } = props;

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
    <div className="border-2 shadow-lg rounded-xl py-2 sm:py-4 px-3 sm:px-6 w-full mb-3">
      <PostProfile
        target_id={target_id}
        targetPersonBgColor={targetPersonBgColor}
        targetPersonTextColor={targetPersonTextColor}
        targetPersonRingColor={targetPersonRingColor}
        name={name}
        person_photo_url={person_photo_url}
        target_post_reputation={target_post_reputation}
      />

      {/* XP Meter */}
      <XpMeter amount={targetPersonXp} />

      {/* Main Photo */}
      {lgLqUrl ? (
        <PostPhoto fullScreenUrl={lgLqUrl} thumbnailUrl={mdHqUrl} />
      ) : (
        <PostPhoto isBlurred repColor={getReputationColor(target_post_reputation)} thumbnailUrl={blUrl} />
      )}

      {/* Bottom Panel */}
      {isLoggedIn && (
        <PostBottomPanel
          targetPersonBgColor={targetPersonBgColor}
          targetPersonTextColor={targetPersonTextColor}
          amount={amount}
        />
      )}
    </div>
  );
};

export default Post;
