import Fuse from "fuse.js";
import localforage from "localforage";

export const getInitials = (name: string) => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials: Array<any> = [name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  return initials;
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const classNames = (...classes: Array<string>) => {
  return classes.filter(Boolean).join(" ");
};

export const randomWithProbability = () => {
  var notRandomNumbers = [1, 1, 1, 1, 2, 2, 2, 3, 3, 4];
  var idx = Math.floor(Math.random() * notRandomNumbers.length);
  return notRandomNumbers[idx];
};

export const randomToken = (len: number) => {
  function dec2hex (dec: any) {
    return dec.toString(16).padStart(2, "0")
  }
  
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const search = (
  query: string,
  unfiltered: Array<any>,
  keys: Array<string>
) => {
  const fuse = new Fuse(unfiltered, {
    keys: keys,
    threshold: 0.2,
  });
  let payload = [];
  if (!query) {
    payload = unfiltered;
  } else {
    const result = fuse.search(query);
    const matches: Array<any> = [];
    result.forEach(({ item }: any) => {
      matches.push(item);
    });
    payload = matches;
  }
  return payload;
};

// Compare two json objects and return an object with only the diffs
export const compareJSON = (obj1: any, obj2: any) => {
  let ret: any = {};
  for (var i in obj2) {
    if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
      ret[i] = obj2[i];
    }
  }
  return ret;
};

export const getImage = async (url: string) => {
  return localforage.getItem(url).then(async (result) => {
    if (result) {
      const url = window.URL.createObjectURL(result);
      const returnObject = {
        url: url,
      };
      return returnObject;
    } else {
      let blob = await fetch(url).then((r) => r.blob());
      return localforage.setItem(url, new Blob([blob])).then((result) => {
        if (result) {
          const url = window.URL.createObjectURL(result);
          const returnObject = {
            url: url,
          };
          return returnObject;
        }
      });
    }
  });
};

export const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(",")[0].indexOf("base64") >= 0
      ? atob(dataURI.split(",")[1])
      : unescape(dataURI.split(",")[1]);
  const mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};
interface IResizeImageOptions {
  maxSize: number;
  file: File;
  compression: Number;
}

export const resizeImage = (settings: IResizeImageOptions) => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const compression = settings.compression;
  const reader = new FileReader();
  const image = new Image();


  const newResize = (size: number) => {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > size) {
        height *= size / width;
        width = size;
      }
    } else {
      if (height > size) {
        width *= size / height;
        height = size;
      }
    }
    return {width: width, height: height}
  }

  const resize = () => {

    const canvas = document.createElement("canvas");
    const lgHq = newResize(maxSize);
    canvas.width = lgHq.width;
    canvas.height = lgHq.height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, lgHq.width, lgHq.height);
    let lgHqUrl = canvas.toDataURL("image/webp",compression);
    return dataURItoBlob(lgHqUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const normalizeArrayByKey = (dataArray: any, key: string) => {
  const assignBy = (key: string) => {
    return (data: any, item: any) => {
      data[item[key]] = item;
      return data;
    };
  };
  return dataArray.reduce(assignBy(key), {});
};
