import QRCode from "qrcode.react";
import { BASE_WEB_URL } from "../../constants/endpoints";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { setActiveToast, setModal, setToast } from "../../store/layout/actions";
import { QRIconOverlay } from "../../components/common/Icons";

export type RootState = ReturnType<typeof store>;

const QR = () => {
  const person = useSelector((state: RootState) => state.people.self);
  const dispatch = useDispatch();

  // copies link to clipboard
  const handleQrButton = () => {
    navigator?.clipboard?.writeText(BASE_WEB_URL + "/" + person.username);
    dispatch(setModal(false));
    dispatch(setActiveToast("LINK"));
    dispatch(setToast());
  };

  return (
    <div className="mx-auto">
      <div className="text-primary-700 text-3xl font-bold mx-auto flex justify-center pb-5">
        {person.name}
      </div>

      <div className="flex justiify-center mx-auto">
        <div className="mx-auto w-full p-10">
          <QRIconOverlay>
            <QRCode
              value={BASE_WEB_URL + "/" + person.username}
              data-testid="qr-image"
              renderAs='svg'
              fgColor='#B91C1C'
              level="H"
            />
          </QRIconOverlay>
          <div className="p-4 ">
            <button
              onClick={() => handleQrButton()}
              className={"text-primary-700"}
              data-testid="qr-link"
            >
              Copy Link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QR;
