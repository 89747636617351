import React from "react";
// import { PersonTag } from ".";
import store from "../../store";
import Posts from "./Posts";
// import SmallDoubloonButton from "./SmallDoubloonButton";
import { RecentPeople } from ".";
import LoginPanel from "./LoginPanel";
import { useSelector } from "react-redux";

export type RootState = ReturnType<typeof store>;

const Feed = () => {

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

  return (
    <>
      {/* <div className=""> */}
      <div className="">
        <RecentPeople />
        {!isLoggedIn && (
          <LoginPanel />
        )}
        <div className="grid lg:grid-cols-2 xl:grid-cols-3 grid-flow-row auto-rows-min">
        <Posts />

        </div>
      </div>
      {/* <div className="invisible sm:visible sm:col-span-4">
          <SmallDoubloonButton />
        </div> */}
      {/* </div> */}
    </>
  );
};

export default Feed;
