
/**
 * TypeScript Types
 */
 export interface LayoutReducerTypes {
    isSlideoutVisible: boolean,
    activeSlideout: string,
    isModalVisible: boolean,
    activeModal: string,
    isToastVisible: boolean,
    activeToast: string,
    isLoading: boolean,
    loadingState: string,
    rehydrated: boolean,
    hasParameter: boolean,
    parameter?: string,
    activeLanding: string,
    activePhoto: string,
  }
  
  /**
   * Action Types
   */
  
  
  export const LAYOUT_SET_SLIDEOUT = 'LAYOUT_SET_SLIDEOUT';
  export const LAYOUT_SET_ACTIVE_SLIDEOUT = 'LAYOUT_SET_ACTIVE_SLIDEOUT';
    
  export const LAYOUT_SET_TOAST = 'LAYOUT_SET_TOAST';
  export const LAYOUT_SET_ACTIVE_TOAST = 'LAYOUT_SET_ACTIVE_TOAST';

  export const LAYOUT_SET_MODAL = 'LAYOUT_SET_MODAL';
  export const LAYOUT_SET_ACTIVE_MODAL = 'LAYOUT_SET_ACTIVE_MODAL';

  export const LAYOUT_SET_LOADING = 'LAYOUT_SET_LOADING';
  export const LAYOUT_SET_LOADING_STATE = 'LAYOUT_SET_LOADING_STATE';

  export const LAYOUT_SET_PARAMETER = 'LAYOUT_SET_PARAMETER';
  export const LAYOUT_HAS_PARAMETER = 'LAYOUT_HAS_PARAMETER';

  export const LAYOUT_SET_ACTIVE_LANDING = 'LAYOUT_SET_ACTIVE_LANDING';
  export const LAYOUT_SET_ACTIVE_PHOTO = 'LAYOUT_SET_ACTIVE_PHOTO';