import classNames from "classnames";
import { SpinnerSvg } from "../Icons";

type ButtonPropType = {
  variant?: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  Icon?: any;
  spinning?: boolean;
  testid?: string;
};

const Button = ({
  variant = "primary",
  label,
  onClick,
  fullWidth = false,
  disabled = false,
  spinning = false,
  Icon,
  testid = 'test-button'
}: ButtonPropType) => {
    
  const btnClass = classNames({
    "inline-flex items-center px-4 py-2 border border-transparent shadow-lg font-medium rounded-md text-primarycontrast bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm":
      variant === "primary",
    "rounded-full hover:text-primary-500 text-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-500":
      variant === "round",
      "rounded-full hover:text-primary-500 text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-500":
      variant === "gray-round",
    "hover:text-white text-primary-200 focus:outline-none ring ring-white bg-white rounded-full":
      variant === "flat-1",
    "hover:text-white text-primary-200 focus:outline-none":
      variant === "flat-2",
    "inline-flex items-center px-4 py-2 border border-transparent font-medium rounded-md text-understated-400 bg-white-100 hover:bg-understated-100 focus:outline-none sm:text-sm":
      variant === "disabled",
    "w-full flex justify-center": fullWidth,
  });

  return (
    <div className="sm:mt-0 sm:flex sm:items-center">
      <button
        onClick={onClick}
        className={btnClass}
        type="button"
        disabled={disabled}
        data-testid={testid ? testid : 'test-button'}
      >
        {(variant === "primary" || variant === "disabled") && (
          <div className="grid grid-cols-3 gap-4 justify-items-center">
            <div className="justify-self-start">{Icon && <Icon />}</div>
            <div className="">{label && label}</div>
            <div className="justify-self-end">
              {spinning && <SpinnerSvg size="h-5 w-5" />}
            </div>
          </div>
        )}

        {(variant === "round" || variant === "gray-round" || variant === "flat" || variant === "flat-1" || variant === "flat-2") && (
          <div className="">
            {spinning ? <SpinnerSvg size="h-5 w-5" /> : <Icon />}
          </div>
        )}
      </button>
    </div>
  );
};

export default Button;
