import React, { useState, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import store from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { setActiveModal } from '../../store/layout/actions';
import { getImage } from '../../services/utils';

export type RootState = ReturnType<typeof store>;




const Photo = () => {
  const activePhoto = useSelector(
    (state: RootState) => state.layout.activePhoto
  );
  const [currentImage, setCurrentImage] = useState(0);
  const [cachedPhoto, setCachedPhoto] = useState([''])
  const dispatch = useDispatch();

  useEffect(() => {
    getImage(activePhoto).then(result => {
      result && setCachedPhoto([result.url]);
    })
  },[activePhoto])


  // const openImageViewer = useCallback((index) => {
  //   setCurrentImage(index);
  //   setIsViewerOpen(true);
  // }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    dispatch(setActiveModal("QR"));
  };

  return (
    <div>
        <ImageViewer
          src={cachedPhoto}
          currentIndex={currentImage}
          onClose={(closeImageViewer)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
    </div>
  );
}

export default Photo;
