import {
  PEOPLE_LOAD,
  PEOPLE_SELF_ACTIVATE,
  PEOPLE_LOAD_ERROR,
  PEOPLE_LOAD_SUCCESS,
  PEOPLE_SELF_LOAD,
  PEOPLE_SELF_LOAD_ERROR,
  PEOPLE_SELF_LOAD_SUCCESS,
  PEOPLE_UPDATE,
  PEOPLE_UPDATE_ERROR,
  PEOPLE_UPDATE_SUCCESS,
  PEOPLE_SELF_UPDATE_BALANCE,
  PEOPLE_UPDATE_ASSET_BALANCE,
  PEOPLE_UPDATE_XP_ASSET_BALANCE,
  PEOPLE_UPDATE_VALIDATION_ERROR,
  PEOPLE_UPDATE_INVALID_FIELDS,
  PEOPLE_SELF_XP_UPDATE_BALANCE
} from "./constants";
import { MemberType } from "../../types";
import { PEOPLE_ENDPOINT, SELF_ENDPOINT } from "../../constants/endpoints";

export const loadPerson = (id: string) => (dispatch: any) => {
  dispatch({
    type: PEOPLE_LOAD,
    payload: {},
    meta: {
      offline: {
        effect: {
          url: PEOPLE_ENDPOINT + "/" + id,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
        commit: { type: PEOPLE_LOAD_SUCCESS, meta: { id } },
        rollback: { type: PEOPLE_LOAD_ERROR, meta: { id } },
      },
    },
  });
};

export const loadSelf = (token: string) => (dispatch: any) => {
  dispatch({
    type: PEOPLE_SELF_LOAD,
    payload: {},
    meta: {
      offline: {
        effect: {
          url: SELF_ENDPOINT,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        },
        commit: { type: PEOPLE_SELF_LOAD_SUCCESS, meta: { token } },
        rollback: { type: PEOPLE_SELF_LOAD_ERROR, meta: { token } },
      },
    },
  });
};


export const personUpdate =
  (token: string, person: MemberType) => (dispatch: any) => {
    dispatch({
      type: PEOPLE_UPDATE,
      payload: {},
      meta: {
        offline: {
          effect: {
            url: PEOPLE_ENDPOINT + '/' + person.id, 
            method: 'PUT', 
            body: JSON.stringify(person), 
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          },
          commit: { type: PEOPLE_UPDATE_SUCCESS, meta: { person }},
          rollback: { type: PEOPLE_UPDATE_ERROR, meta: { person }}
          }
      }
    })
  };

export const selfActivate = (self: MemberType) => (dispatch: any) => {
  dispatch({ type: PEOPLE_SELF_ACTIVATE, payload: self });
  return self;
};

export const setSelfDoubloonBalance = (balance: number) => (dispatch: any) => {
  dispatch({ type: PEOPLE_SELF_UPDATE_BALANCE, payload: balance });
}

export const setSelfXpBalance = (balance: number) => (dispatch:any) => {
  dispatch({ type: PEOPLE_SELF_XP_UPDATE_BALANCE, payload: balance });

}

export const setAssetBalance = (balance: number) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_ASSET_BALANCE, payload: balance })
}

export const setXpAssetBalance = (balance: number) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_XP_ASSET_BALANCE, payload: balance })
}

export const setValidationError = (error: string) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_VALIDATION_ERROR, payload: error })
}

export const setInvalidFields = (error: Array<string>) => (dispatch: any) => {
  dispatch({ type: PEOPLE_UPDATE_INVALID_FIELDS, payload: error })
}