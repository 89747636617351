import React from 'react';
import { getReputation } from '../../services/xpService';

type XpMeterPropsType = {
    amount: number
}

const XpMeter = (props: XpMeterPropsType) => {
    const { amount } = props;

    const personRep = getReputation(amount);
    const bgColor = 'bg-'+personRep.color+'-700'
    const classWidth = personRep.classWidth

    return (
        <div className="h-2 mt-2 w-full relative rounded-full overflow-hidden">
        <div className="w-full h-full bg-gray-200 absolute"></div>
        <div
          className={
            "h-full rounded-full absolute " +
            bgColor +
            " " +
            classWidth
          }
        ></div>
      </div>
    )
}

export default XpMeter;