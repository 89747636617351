
/**
 * TypeScript Types
 */
import { MemberType, PersonType } from '../../types';

export interface PeopleReducerTypes {
  byId: { [id: string]: PersonType };
  isPeopleLoading: boolean,
  activePerson: PersonType,
  self: MemberType,
  stripe_account_link?: string | null,
  recent: Array<string>,
}

/**
 * Action Types
 */

export const PEOPLE_SELF_ACTIVATE = 'PEOPLE_SELF_ACTIVATE';

export const PEOPLE_SELF_LOAD = 'PEOPLE_SELF_LOAD';
export const PEOPLE_SELF_LOAD_SUCCESS = 'PEOPLE_SELF_LOAD_SUCCESS';
export const PEOPLE_SELF_LOAD_ERROR = 'PEOPLE_SELF_LOAD_ERROR';

export const PEOPLE_LOAD = 'PEOPLE_LOAD'
export const PEOPLE_LOAD_SUCCESS = 'PEOPLE_LOAD_SUCCESS';
export const PEOPLE_LOAD_ERROR = 'PEOPLE_LOAD_ERROR';

export const PEOPLE_UPDATE = 'PEOPLE_UPDATE';
export const PEOPLE_UPDATE_SUCCESS = 'PEOPLE_UPDATE_SUCCESS';
export const PEOPLE_UPDATE_ERROR = 'PEOPLE_UPDATE_ERROR';

export const PEOPLE_SELF_UPDATE_BALANCE = 'PEOPLE_SELF_UPDATE_BALANCE';
export const PEOPLE_SELF_XP_UPDATE_BALANCE = 'PEOPLE_SELF_XP_UPDATE_BALANCE';
export const PEOPLE_UPDATE_ASSET_BALANCE = 'PEOPLE_UPDATE_ASSET_BALANCE';
export const PEOPLE_UPDATE_XP_ASSET_BALANCE = 'PEOPLE_UPDATE_XP_ASSET_BALANCE';
export const PEOPLE_UPDATE_VALIDATION_ERROR = 'PEOPLE_UPDATE_VALIDATION_ERROR';
export const PEOPLE_UPDATE_INVALID_FIELDS = 'PEOPLE_UPDATE_INVALID_FIELDS';