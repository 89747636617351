import React from "react";
import { CloudUploadIcon } from "../common/Icons";
import {
  addFiles,
  setActiveFiles,
  setFeedFiles,
  setGridFiles,
} from "../../store/files/actions";
import {
  normalizeArrayByKey,
  randomToken,
  resizeImage,
  dataURItoBlob,
  getImage,
} from "../../services/utils";
import smartcrop from "smartcrop";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import firebase from "firebase/app";
import "firebase/storage";
import { FileType } from "../../types";

export type RootState = ReturnType<typeof store>;

type FileUploadPropsType = {
  setIsLoading: Function;
  setActiveLoadingMessage: Function;
  bidValue: number;
};

const FileUpload = (props: FileUploadPropsType) => {
  const storage = firebase.storage();

  const { setIsLoading, setActiveLoadingMessage, bidValue } = props;

  const dispatch = useDispatch();
  const selectedArray = useSelector(
    (state: RootState) => state.files.activeReps
  );

  const self_id = useSelector((state: RootState) => state.people.self.id);

  const token = useSelector((state: RootState) => state.auth.token);

  const imgLoaded = async (img: any) => {
    return new Promise(function (resolve, reject) {
      img.addEventListener("load", function () {
        resolve(img);
      });
    });
  };

  const firebaseUpload = async (
    parent_id: string,
    child_id: string,
    blob: Blob
  ) => {
    setActiveLoadingMessage("Uploading Image");
    return storage
      .ref("/files/" + parent_id + "/" + child_id)
      .put(blob)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL().then((result) => {
          return result;
        });
      });
  };

  const handleFileInput = async (e: any) => {
    setIsLoading(true);
    let files: Array<FileType> = [];

    for (let file of e.target.files) {
      setActiveLoadingMessage("Sharding Image");
      // Generate Original Local URL
      const localUrl = URL.createObjectURL(file);
      // Make unique ids
      const randomid = randomToken(48);
      const mdLqImageToken = randomToken(48);
      const mdImageToken = randomToken(48);
      const lgHqImageToken = randomToken(48);
      const lgLqImageToken = randomToken(48);
      const sqImageToken = randomToken(48);
      const blImageToken = randomToken(48);
      // Generate Resize Settings
      const lgSettings = {
        id: randomid,
        file: file,
        maxSize: 1920,
        compression: 0.75,
      };
      const lgLqSettings = {
        id: randomid,
        file: file,
        maxSize: 1920,
        compression: 0.25,
      };
      const mdSettings = {
        id: randomid,
        file: file,
        maxSize: 640,
        compression: 0.75,
      };
      const mdLqSettings = {
        id: randomid,
        file: file,
        maxSize: 640,
        compression: 0.25,
      };
      const smSettings = {
        id: randomid,
        file: file,
        maxSize: 320,
        compression: 0.5,
      };
      // Resize Initial Images
      const lgImage: any = await resizeImage(lgSettings);
      const lgLqImage: any = await resizeImage(lgLqSettings);
      const mdImage: any = await resizeImage(mdSettings);
      const mdLqImage: any = await resizeImage(mdLqSettings);
      const smImage: any = await resizeImage(smSettings);

      const smallImage = URL.createObjectURL(smImage);
      const mediumImage = URL.createObjectURL(mdImage);

      setActiveLoadingMessage("Processing Cover Image");

      const mdPromise: Promise<Blob> = new Promise((resolve) => {
        const image = new Image();
        image.src = mediumImage;
        setActiveLoadingMessage("Medium Promise Loaded");

        imgLoaded(image).then(() => {
          setActiveLoadingMessage("Medium Image Loaded");
          smartcrop
            .crop(image, {
              width: 640,
              height: 360,
              minScale: 1,
            })
            .then((result) => {
              const { topCrop } = result;
              const canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.width / 1.777;
              const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
              ctx?.drawImage(
                image,
                topCrop.x,
                topCrop.y,
                topCrop.width,
                topCrop.height,
                0,
                0,
                640,
                360
              );
              let mdCrop = canvas.toDataURL("image/webp");
              let blob = dataURItoBlob(mdCrop);
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              resolve(blob);
            });
        });
      });

      let mdHqUrl = "";
      let mdImageImage = new Blob();

      setActiveLoadingMessage("Uploading Cover Image");
      const mdUrlPromise: Promise<string> = new Promise((resolve) => {
        return mdPromise.then((result) => {
          mdImageImage = result;
          return firebaseUpload(randomid, mdImageToken, result).then(
            (result) => {
              mdHqUrl = result;
              resolve(result);
            }
          );
        });
      });

      await mdUrlPromise;

      setActiveLoadingMessage("Uploading Image Shards");

      // Upload large high quality image to firebase
      let lgHqUrl = "";
      await firebaseUpload(randomid, lgHqImageToken, lgImage).then((result) => {
        lgHqUrl = result;
      });

      // Upload large low quality image to firebase
      let lgLqUrl = "";
      await firebaseUpload(randomid, lgLqImageToken, lgLqImage).then(
        (result) => {
          lgLqUrl = result;
        }
      );

      // Upload medium low quality image to firebase
      let mdLqUrl = "";
      await firebaseUpload(randomid, mdLqImageToken, mdLqImage).then(
        (result) => {
          mdLqUrl = result;
        }
      );

      URL.revokeObjectURL(localUrl);

      setActiveLoadingMessage("Processing Thumbnail");

      // Generate Square Thumbnail
      const sqPromise: Promise<Blob> = new Promise((resolve) => {
        const image = new Image();
        image.src = smallImage;
        imgLoaded(image).then(() => {
          let width = image.width;
          let height = image.height;
          if (width > height) {
            width = height;
          } else {
            height = width;
          }
          smartcrop
            .crop(image, {
              width: width,
              height: height,
            })
            .then((result) => {
              const { topCrop } = result;
              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
              ctx?.drawImage(
                image,
                topCrop.x,
                topCrop.y,
                topCrop.width,
                topCrop.height,
                0,
                0,
                width,
                height
              );
              let sqCrop = canvas.toDataURL("image/webp");
              let blob = dataURItoBlob(sqCrop);
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              resolve(blob);
            });
        });
      });

      setActiveLoadingMessage("Uploading Thumbnail");
      let sqUrl = "";
      // Upload square image to firebase
      const sqUrlPromise: Promise<string> = new Promise((resolve) => {
        return sqPromise.then((result) => {
          return firebaseUpload(randomid, sqImageToken, result).then(
            (result) => {
              sqUrl = result;
              resolve(sqUrl);
            }
          );
        });
      });

      await sqUrlPromise;

      setActiveLoadingMessage("Blurring File");

      // Blur
      // Filter isn't working in safari so I'm just using an alpha channel.
      // Either wait for Safari support or figure out a better blur
      const blPromise: Promise<Blob> = new Promise((resolve) => {
        const blurImage = new Image();
        blurImage.src = URL.createObjectURL(mdImageImage);
        imgLoaded(blurImage).then(() => {
          const blurcanvas = document.createElement("canvas");
          blurcanvas.width = blurImage.width;
          blurcanvas.height = blurImage.height;
          const blurctx = blurcanvas.getContext(
            "2d"
          ) as CanvasRenderingContext2D;
          // blurctx.filter = "blur(10px)";
          blurctx.globalAlpha = 0.125;
          blurctx.drawImage(blurImage, 0, 0);
          let blurred = blurcanvas.toDataURL("image/webp");
          let blurblob = dataURItoBlob(blurred);
          blurctx.clearRect(0, 0, blurcanvas.width, blurcanvas.height);
          resolve(blurblob);
        });
      });

      let blUrl = "";
      const blUrlPromise: Promise<string> = new Promise((resolve) => {
        return blPromise.then((result) => {
          return firebaseUpload(randomid, blImageToken, result).then(
            (result) => {
              blUrl = result;
              resolve(blUrl);
            }
          );
        });
      });

      setActiveLoadingMessage("Uploading Blurred File");

      await blUrlPromise;

      // This caches the local files in loalforage to make them persistent
      setActiveLoadingMessage("Caching Files");
      await getImage(lgHqUrl);
      await getImage(mdHqUrl);
      await getImage(lgLqUrl);
      await getImage(mdLqUrl);
      await getImage(sqUrl);
      await getImage(blUrl);

      setActiveLoadingMessage("Updating Local State");
      // Get Post Rep
      const lowestRep = Math.min(...selectedArray);
      let reputation = "NEUTRAL";
      lowestRep === 0 && (reputation = "NEUTRAL");
      lowestRep === 1 && (reputation = "FRIENDLY");
      lowestRep === 2 && (reputation = "HONORED");
      lowestRep === 3 && (reputation = "REVERED");
      lowestRep === 4 && (reputation = "EXALTED");

      files.push({
        id: randomid,
        lg_hq_url: lgHqUrl,
        md_hq_url: mdHqUrl,
        lg_lq_url: lgLqUrl,
        md_lq_url: mdLqUrl,
        bl_url: blUrl,
        sq_url: sqUrl,
        reputation: reputation,
        bid: bidValue,
        person_id: self_id,
        status: "UPLOADED",
      });

      const normalizedFiles = normalizeArrayByKey(files, "id");
      token && dispatch(addFiles(files, normalizedFiles, token));
      dispatch(setActiveFiles(randomid));
      dispatch(setFeedFiles(randomid));
      dispatch(setGridFiles(randomid));
      setActiveLoadingMessage("Image Ready to Post");
    }

    setIsLoading(false);
  };

  return (
    <div className="rounded-md py-4 sm:flex sm:items-start sm:justify-between">
      <label className="w-full flex flex-cols-2 items-center py-4 bg-primary-700 rounded-md shadow-md tracking-wide uppercase border border-primary cursor-pointer hover:bg-primary-900 hover:text-white text-white ease-linear transition-all duration-150">
        <div className="px-4">
          <CloudUploadIcon />
        </div>
        <div>
          <span className="text-lg font-bold">Select a file</span>
        </div>
        <input
          type="file"
          onChange={(e: any) => {
            handleFileInput(e);
          }}
          className="hidden"
        />
      </label>
    </div>
  );
};

export default FileUpload;
