import React from "react";
import { Image } from "../../components/common/Image";
import { useDispatch } from "react-redux";
import { setActiveModal, setActivePhoto } from "../../store/layout/actions";
import { LockClosedIcon } from "../common/Icons";

type PostPhotoPropsType = {
  fullScreenUrl?: string;
  thumbnailUrl: string;
  isBlurred?: boolean;
  repColor?: string;
};

const PostPhoto = (props: PostPhotoPropsType) => {
  const { fullScreenUrl, thumbnailUrl, isBlurred, repColor } = props;
  const dispatch = useDispatch();
  const handlePhotoClick = (photo: string) => {
    !isBlurred && dispatch(setActiveModal("PHOTO"));
    !isBlurred && dispatch(setActivePhoto(photo));
  };

  return (
    <>
    <button
      onClick={() => {
        fullScreenUrl && handlePhotoClick(fullScreenUrl)
      }}
      className="focus:outline-none w-full relative pt-1"
      data-testid="button-test"
    >
      {isBlurred && (
        <div className="absolute">
          <div className="text-white flex py-16 px-36">
            <LockClosedIcon />
          </div>       
        </div>
      )}
      <div
        className={`flex bg-${repColor}-400 rounded-md w-full `}
      >
          <div className="">
            <Image
              url={thumbnailUrl}
              className="rounded-md w-full"
            />
          </div>
      </div>
    </button>
    </>
  );
};

export default PostPhoto;
