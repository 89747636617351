import { Reducer } from 'redux';
import { 
    MoneyReducerType,
    MONEY_ACTIVATE,
    TRANSACTION_SUCCESS,
    TRANSACTION_ERROR,
    TRANSACTION_BEGIN,
    TRANSACTION_LOAD_CONFIRM,
    TRANSACTION_ADD_CONFIRM,
    TRANSACTION_LOG_BEGIN,
    TRANSACTION_LOG_SUCCESS,
    TRANSACTION_LOG_ERROR,
    BALANCES_BEGIN,
    BALANCES_SUCCESS,
    BALANCES_ERROR
} from './constants';


const defaultState = {
    balances: [],
    transactionAmount: 0,
    isTransactionLoading: false,
    transactionType: 'NONE',
    activeTransactions: [],
    stagedTransactions: [],
    confirmedTransactions: [],
};



const moneyReducer: Reducer<MoneyReducerType> = (
    state = defaultState,
    action: { type: String; payload?: any, meta?: any }
  ) => {
    switch (action.type) {
          case MONEY_ACTIVATE: {
            return {
              ...state,
              transactionAmount: action.payload.transactionAmount,
            };
          }
          case TRANSACTION_BEGIN: {
            const newArray = [...state.activeTransactions, action.payload.transaction];
            return {
              ...state,
              isTransactionLoading: true,
              activeTransactions: newArray,
            }
          }
          case TRANSACTION_SUCCESS: {
            const newArray = state.activeTransactions.filter(trans => trans.id !== action.meta.transaction.id);
            return {
              ...state,
              isTransactionLoading: false,
              activeTransactions: newArray
            }
          }case TRANSACTION_ERROR: {
            const newArray = state.activeTransactions.filter(trans => trans.id !== action.meta.transaction.id);
            return {
              ...state,
              isTransactionLoading: false,
              activeTransactions: newArray,
              stagedTransactions: [...state.stagedTransactions, action.meta.transaction]
            }
          }
          case TRANSACTION_LOAD_CONFIRM: {
            return {
              ...state,
              confirmedTransactions: action.payload.transactions
            }
          }
          case TRANSACTION_ADD_CONFIRM: {
            return {
              ...state,
              confirmedTransactions: [action.payload.transaction, ...state.confirmedTransactions]
            }
          }
          case TRANSACTION_LOG_BEGIN: {
            return {
              ...state,
              isTransactionLoading: true,
            }
          }
          case TRANSACTION_LOG_SUCCESS: {
            return {
              ...state,
              isTransactionLoading: false,
              confirmedTransactions: [...action.payload.success]
            }
          }case TRANSACTION_LOG_ERROR: {
            return {
              ...state,
              isTransactionLoading: false,
              confirmedTransactions: []
            }
          }
          case BALANCES_BEGIN: {
            return {
              ...state,
              isTransactionLoading: true,
            }
          }
          case BALANCES_SUCCESS: {
            return {
              ...state,
              isTransactionLoading: false,
              balances: [...action.payload.success.balances]
            }
          }case BALANCES_ERROR: {
            return {
              ...state,
              isTransactionLoading: false,
              balances: []
            }
          }
        default:
            return state;
    }
};

export default moneyReducer;