import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIconSvg } from "../Icons";
import { useSelector, useDispatch } from "react-redux";
import { setModal } from "../../../store/layout/actions";
import store from "../../../store";
export type RootState = ReturnType<typeof store>;

type ModalPropsType = {
  children?: React.ReactNode;
};

const Modal = (props: ModalPropsType) => {
  const { children } = props;

  const dispatch = useDispatch();

  const cancelButtonRef = useRef(null);
  const [title, setTitle] = useState("Welcome to Libertalia");
  const activeModal = useSelector(
    (state: RootState) => state.layout.activeModal
  );
  const isOpen = useSelector((state: RootState) => state.layout.isModalVisible);

  useEffect(() => {
    activeModal === "LOGIN" && setTitle("");
    activeModal === "BUY_DOUBLOONS" && setTitle("");
    activeModal === "PAYOUT" && setTitle("");
    activeModal === "QR" && setTitle("");
    activeModal === "PHOTO" && setTitle("");
    activeModal === "POST" && setTitle("");
  }, [activeModal, setTitle]);

  const close = () => {
    dispatch(setModal(false));
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-0 inset-0 overflow-y-auto h-full"
        initialFocus={cancelButtonRef}
        onClose={() => close()}
        open={isOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 sm:px-2 pb-32 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-in duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="visible z-0 absolute h-full inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

            <>
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <Transition.Child
                as={Fragment}
                enter="ease-in duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="border-2 inline-block align-bottom bg-white rounded-lg px-1 pt-3 pb-4 overflow-hidden shadow-xl transform transition-all mx-2 w-full sm:my-10 sm:pt-4 sm:align-middle sm:max-w-lg">
                  <div className="">
                    <div className="flex items-end">
                      <div className="flex-grow w-max"> </div>
                      <div className="flex-shrink h-5 w-5 mr-2 ">
                        <button
                          data-testid="test-close-modal"
                          className="focus:outline-none"
                          onClick={() => close()}
                          ref={cancelButtonRef}
                        >
                          <XIconSvg />
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="px-2" data-testid="modal-container">
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </>
          {/* )} */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
