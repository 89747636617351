import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveSlideout, setSlideout } from "../../store/layout/actions";
import { loadPerson } from "../../store/people/actions";
import { Image } from '../../components/common/Image';
import store from "../../store";

export type RootState = ReturnType<typeof store>;

type RecentPersonPropsType = {
  initials: string;
  photo_url: string;
  target_id: string;
  ringColor: string;
  bgColor: string;
};

const RecentPerson = (props: RecentPersonPropsType) => {
  const { initials, photo_url, target_id, ringColor, bgColor } = props;

  const dispatch = useDispatch();

  const [loaded, setLoaded ] = useState(false);

  const handleProfileClick = (target_id: string) => {
    dispatch(loadPerson(target_id));
    dispatch(setActiveSlideout("TARGET_PROFILE"));
    dispatch(setSlideout(true));
  };

  return (
    <button onClick={() => handleProfileClick(target_id)}
    className="flex"
    >
      {!loaded && (
        <div className={"mx-auto pb-2 md:p-3 h-14 w-14 md:h-18 md:w-18 rounded-full text-xl md:text-3xl text-white " + bgColor + ' ' + ringColor}>
          {initials}
        </div>
      )}
      <Image
        className={"h-12 w-12 md:h-16 md:w-16 ring rounded-full " + ringColor }
        url={photo_url}
        onLoad={() => setLoaded(true)}
      />
    </button>
  );
};

export default RecentPerson;
