import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Landing, Redirect } from "../../screens/Landing";
import { useDispatch } from "react-redux";
import store from "../../store";
import { logout } from "../../store/auth/actions";
import { BASE_WEB_URL } from "../../constants/endpoints";
export type RootState = ReturnType<typeof store>;

const RoutingService = () => {
  const dispatch = useDispatch();

  return (
    <div data-testid="router-test">
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/expired">
            <Redirect />
          </Route>
          <Route exact path="/destroy">
            <Destroy dispatch={dispatch} logout={logout} />
          </Route>
          <Route exact path="/:target_id">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

type DestroyProps = {
  logout: Function;
  dispatch: Function;
};

export const Destroy = (props: DestroyProps) => {
  const { logout, dispatch } = props;
  useEffect(() => {
    dispatch(logout());
    BASE_WEB_URL && window.location.assign(BASE_WEB_URL);
  }, [logout, dispatch]);
  return <></>;
};

export default RoutingService;
