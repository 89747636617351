import React, { useState } from "react";
import { FirebaseLogin } from "../../components/FirebaseLogin";
import {
  TUTORIAL_ONE,
  TUTORIAL_TWO,
  TUTORIAL_THREE,
  LOGIN_EXISTING_DEVICE,
} from "../../constants/copy";
import { Button } from "../../components/common/Button";
import store from "../../store";
import { BigParrotSvg } from "../../components/common/Icons";
import Doubloon1 from "../../components/common/Icons/Coins/Sprites/Doubloon1.svg";
import Doubloon2 from "../../components/common/Icons/Coins/Sprites/Doubloon2.svg";

export type RootState = ReturnType<typeof store>;

type TutorialPropsType = {
  page: number;
  setActivePage: Function;
};
const Tutorial = (props: TutorialPropsType) => {
  const { page, setActivePage } = props;

  const handleButtonClick = () => {
    setActivePage(page);
  };

  return (
    <div className="grid grid-cols-3">
      <div> </div>
      <div className="pt-4">
        <Button
          label="Next"
          fullWidth
          onClick={() => handleButtonClick()}
        />
      </div>
    </div>
  );
};

const Login = () => {
  const isFirstTime = !window.localStorage.getItem("token");

  const [activePage, setActivePage] = useState(1);

  return (
    <>
      <div className="">
        {isFirstTime ? (
          activePage === 1 && (
            <div>
              <div className="flex justify-center">
                <BigParrotSvg />
              </div>
              <div className="py-5 text-md text-gray-500">{TUTORIAL_ONE}</div>
              <Tutorial setActivePage={setActivePage} page={2} />
            </div>
          )
        ) : (
          <div>
            {LOGIN_EXISTING_DEVICE}
            <div className="mx-auto mt-5 sm:mt-6">
              <FirebaseLogin />
            </div>
          </div>
        )}

        {activePage === 2 && (
          <div className="mx-auto mt-5 sm:mt-6">
            <div className="flex justify-center mx-auto h-32 w-32 items-center">
              <object type="image/svg+xml" className="p-5 flex mx-auto" data={Doubloon1}>
                svg-animation
              </object>
            </div>
            <div className="py-5 mt-5 text-md text-gray-500">
              {TUTORIAL_TWO} <Tutorial setActivePage={setActivePage} page={3} />
            </div>
          </div>
        )}

        {activePage === 3 && (
          <div className="mx-auto mt-5 sm:mt-6">
            <div className="flex justify-center mx-auto h-32 w-32 items-center">
              <object type="image/svg+xml" className="p-5 flex mx-auto" data={Doubloon2}>
                svg-animation
              </object>
            </div>
            <div className="py-5 mt-5 text-md text-gray-500">
              {TUTORIAL_THREE}{" "}
              <Tutorial setActivePage={setActivePage} page={4} />
            </div>
          </div>
        )}
      </div>

      {activePage === 4 && (
        <div className="">
            <FirebaseLogin />
        </div>
      )}

    </>
  );
};

export default Login;
