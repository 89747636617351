import React, { useState, useEffect } from "react";
import { getImage } from "../../../services/utils";

type ImagePropsType = {
  url: string;
  className?: string;
  onLoad?: Function;
};

const Image = (props: ImagePropsType) => {
  const { url, className, onLoad } = props;
  const [cachedImage, setCachedImage] = useState<any>("");

  useEffect(() => {
    getImage(url).then((result) => {
      setCachedImage(result?.url);
    });
  }, [url]);

  return (
    <div>
      <img
        src={cachedImage}
        alt=""
        className={className}
        loading="lazy"
        onLoad={() => {
          if (onLoad) {
            onLoad(true);
          }
        }}
      />
    </div>
  );
};

export default Image;
