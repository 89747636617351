/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import {
  NeutralSm,
  FriendlySm,
  HonoredSm,
  ReveredSm,
  ExaltedSm,
} from "../common/Icons";
import { setActiveRep } from '../../store/files/actions';
import { useSelector, useDispatch } from 'react-redux';
import store from "../../store";
export type RootState = ReturnType<typeof store>;

const plans = [
  {
    name: "Neutral",
    color: "text-gray-700",
    bgColor: "bg-gray-700",
    index: 0,
    icon: NeutralSm,
  },
  {
    name: "Friendly",
    color: "text-green-700",
    bgColor: "bg-green-700",
    index: 1,
    icon: FriendlySm,
  },
  {
    name: "Honored",
    color: "text-blue-700",
    bgColor: "bg-blue-700",
    index: 2,
    icon: HonoredSm,
  },
  {
    name: "Revered",
    color: "text-purple-700",
    bgColor: "bg-purple-700",
    index: 3,
    icon: ReveredSm,
  },
  {
    name: "Exalted",
    color: "text-red-700",
    bgColor: "bg-red-700",
    index: 4,
    icon: ExaltedSm,
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [selected, setSelected] = useState(plans[4]);
  // const [selectedArray, setSelectedArray] = useState([4]);
  const selectedArray = useSelector((state: RootState) => state.files.activeReps);

  const dispatch = useDispatch();

  useEffect(() => {
    selected.index === 0 && dispatch(setActiveRep([0, 1, 2, 3, 4]));
    selected.index === 1 && dispatch(setActiveRep([1, 2, 3, 4]));
    selected.index === 2 && dispatch(setActiveRep([2, 3, 4]));
    selected.index === 3 && dispatch(setActiveRep([3, 4]));
    selected.index === 4 && dispatch(setActiveRep([4]));
  }, [selected, dispatch]);

  return (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className="">
        {plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ active }) =>
              classNames(
                selectedArray.includes(plan.index) ? plan.bgColor : "",
                " relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 mb-1 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
              )
            }
          >
            {({ checked }) => (
              <>
                <div className={"grid grid-cols-5 items-center"}>
                <img src={plan.icon} alt="" className="flex flex-shrink" />

                  <div className="text-lg flex justify-start flex-grow col-span-4">
                    <RadioGroup.Label
                      as="p"
                      className={classNames(
                        selectedArray.includes(plan.index)
                          ? "text-white"
                          : plan.color,
                        " text-md font-bold pl-4"
                      )}
                    >
                      {plan.name}
                    </RadioGroup.Label>
                  </div>
                </div>
                <div aria-hidden="true" />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
