import React from 'react';
import { Thumbnail } from '.';
import { useSelector } from "react-redux";
import store from "../../store";
import { hasPermission, getReputation } from '../../services/xpService';

export type RootState = ReturnType<typeof store>;

const AssetGrid = () => {

    const gridFileDetails = useSelector(
        (state: RootState) => state.people.activePerson.files
      );

      const xpBalance = useSelector(
        (state: RootState) => state.people.activePerson.xp_asset_balance
      );

      const reputation = getReputation(xpBalance);


    return (
        <div className="grid grid-rows-3 flex bg-white pt-2 relative justify-center">
        <div className="grid grid-cols-3 flex">
          {gridFileDetails && (
            <>
              <Thumbnail
                square={gridFileDetails[0]?.sq_url}
                photo={gridFileDetails[0]?.lg_lq_url}
                blur={gridFileDetails[0]?.bl_url}
                rep={gridFileDetails[0]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[0]?.reputation)}
              />
              <Thumbnail
                square={gridFileDetails[1]?.sq_url}
                photo={gridFileDetails[1]?.lg_lq_url}
                blur={gridFileDetails[1]?.bl_url}
                rep={gridFileDetails[1]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[1]?.reputation)}

              />
              <Thumbnail
                square={gridFileDetails[2]?.sq_url}
                photo={gridFileDetails[2]?.lg_lq_url}
                blur={gridFileDetails[2]?.bl_url}
                rep={gridFileDetails[2]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[2]?.reputation)}
              />
            </>
          )}
        </div>
        <div className="grid grid-cols-3 flex">
          {gridFileDetails && (
            <>
              <Thumbnail
                square={gridFileDetails[3]?.sq_url}
                photo={gridFileDetails[3]?.lg_lq_url}
                blur={gridFileDetails[3]?.bl_url}
                rep={gridFileDetails[3]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[3]?.reputation)}

              />
              <Thumbnail
                square={gridFileDetails[4]?.sq_url}
                photo={gridFileDetails[4]?.lg_lq_url}
                blur={gridFileDetails[4]?.bl_url}
                rep={gridFileDetails[4]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[4]?.reputation)}

              />
              <Thumbnail
                square={gridFileDetails[5]?.sq_url}
                photo={gridFileDetails[5]?.lg_lq_url}
                blur={gridFileDetails[5]?.bl_url}
                rep={gridFileDetails[5]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[5]?.reputation)}

              />
            </>
          )}
        </div>
        <div className="grid grid-cols-3 flex">
          {gridFileDetails && (
            <>
              <Thumbnail
                square={gridFileDetails[6]?.sq_url}
                photo={gridFileDetails[6]?.lg_lq_url}
                blur={gridFileDetails[6]?.bl_url}
                rep={gridFileDetails[6]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[6]?.reputation)}

              />

              <Thumbnail
                square={gridFileDetails[7]?.sq_url}
                photo={gridFileDetails[7]?.lg_lq_url}
                blur={gridFileDetails[7]?.bl_url}
                rep={gridFileDetails[7]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[7]?.reputation)}

              />

              <Thumbnail
                square={gridFileDetails[8]?.sq_url}
                photo={gridFileDetails[8]?.lg_lq_url}
                blur={gridFileDetails[8]?.bl_url}
                rep={gridFileDetails[8]?.reputation}
                isBlurred={!hasPermission(reputation.reputation, gridFileDetails[8]?.reputation)}

              />
            </>
          )}
        </div>
      </div>
    )
}

export default AssetGrid;