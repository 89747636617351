import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveSlideout, setSlideout } from "../../store/layout/actions";
import { loadPerson } from "../../store/people/actions";
import { getInitials } from "../../services/utils";
import PostReputationIndicator from './PostReputationIndicator';

type PostProfilePropsType = {
    target_id: string,
    targetPersonBgColor: string,
    targetPersonTextColor: string,
    targetPersonRingColor: string,
    name: string,
    person_photo_url: string,
    subtitle?: string,
    target_post_reputation: string,
}

const PostProfile = (props: PostProfilePropsType) => {
    const {
        target_id,
        targetPersonBgColor,
        targetPersonRingColor,
        targetPersonTextColor,
        name,
        person_photo_url,
        target_post_reputation,
    } = props;

  const dispatch = useDispatch();
  const [avatarLoaded, setAvatarLoaded] = useState(false);

  const handleProfileClick = (target_id: string) => {
    dispatch(loadPerson(target_id));
    dispatch(setActiveSlideout("TARGET_PROFILE"));
    dispatch(setSlideout(true));
  };

  return (
    <button
      onClick={() => handleProfileClick(target_id)}
      className="focus:outline-none grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 pt-2 w-full justify-between"
      data-testid="button-test"
    >
      <div className="block h-12 w-12 md:h-16 md:w-16">
        {!avatarLoaded && (
          <div
            className={
              "p-2 md:p-3 h-12 w-12 md:h-16 md:w-16 ring rounded-full text-xl md:text-3xl text-white " +
              targetPersonBgColor +
              " " +
              targetPersonRingColor
            }
          >
            {getInitials(name)}
          </div>
        )}
        <img
          className={
            "h-12 w-12 md:h-16 md:w-16 ring-2 rounded-full " +
            targetPersonBgColor +
            " " +
            targetPersonRingColor
          }
          style={{ display: avatarLoaded ? "block" : "none" }}
          src={person_photo_url}
          alt=""
          onLoad={() => setAvatarLoaded(true)}
        />
      </div>
      <div className="-ml-4 md:ml-4 md:pl-4 grid grid-rows-2 col-span-2 sm:col-span-4 md:col-span-6">
        <div
          className={
            "flex justify-start text-md sm:text-lg font-bold " +
            targetPersonTextColor
          }
        >
          {name}
        </div>
      </div>
      <div className="text-xs flex self-end justify-self-end text-gray-500 text-xs ">
        <div className="row-span-1 mb-0">
          <PostReputationIndicator
            target_post_reputation={target_post_reputation}
          />
        </div>
      </div>
    </button>
  );
};

export default PostProfile;
